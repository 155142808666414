import React from "react";
import groupImage from "../../assests/EngineeringGroup.png";

const HowWeWorkSection = () => {
  const DashedLine = () => (
    <div
      className="absolute left-[35px] top-[48px] md:bottom-[135px] bottom-[220px] w-[3px]"
      style={{
        backgroundImage: "linear-gradient(#FF9130 33%, transparent 33%)",
        backgroundSize: "3px 13px",
        backgroundPosition: "right",
        backgroundRepeat: "repeat-y",
      }}
    />
  );

  return (
    <>
      <div className="flex lg:flex-row flex-col lg:py-[100px] lg:px-[80px] larger:px-[200px] mx-auto max-w-[2000px] py-16 px-3 lg:gap-10 gap-14">
        <div className="lg:w-[50%]  w-full">
          <img
            src={groupImage}
            alt="groupImage"
            className="lg:max-h-[1100px] w-full"
          />
        </div>
        <div className="flex flex-col items-start justify-center lg:w-[50%] w-full">
          <h2 className="semiHeading text-left">OUR PROCESS</h2>
          <h2 className="heading1 lg:w-[80%] md:mb-12 mb-6">
            This is How We Work
          </h2>
          <div>
            <div className="p-4 flex flex-col gap-4 relative">
              <DashedLine />

              {[
                {
                  number: 1,
                  title: "Discovery & Planning",
                  description:
                    "We start by understanding your business goals and requirements to create a detailed project roadmap. This phase sets the foundation, ensuring alignment and clear objectives for development.",
                },
                {
                  number: 2,
                  title: "Design & Prototyping",
                  description:
                    "Our team develops user-friendly designs and interactive prototypes that visualize the end product. This helps gather feedback early, ensuring the product meets your vision before full development.",
                },
                {
                  number: 3,
                  title: "Development & Testing",
                  description:
                    "Using agile methodologies, we build and test the solution in iterative cycles, ensuring high quality and functionality. Rigorous testing is conducted to catch and resolve issues early, ensuring a robust final product.",
                },
                {
                  number: 4,
                  title: "Deployment",
                  description:
                    "Once development is complete, we deploy the solution to your chosen environment, handling all setup requirements. Our team ensures a seamless transition and configures the product for optimal performance.",
                },
                {
                  number: 5,
                  title: "Support & Maintenance",
                  description:
                    "Post-launch, we provide ongoing support to ensure your product runs smoothly and stays updated. Regular maintenance and enhancements keep it aligned with changing needs and technologies.",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex md:flex-row space-y-4 md:space-y-0 md:space-x-4"
                >
                  <div className="flex-shrink-0 -mb-6 mt-4 z-20">
                    <div className="h-10 w-10 bg-primary rounded-lg border-[3px] border-secondary flex items-center justify-center text-white font-bold">
                      {item.number}
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="px-4 md:py-3">
                      <h4 className="font-bold text-xl text-gray-900">
                        {item.title}
                      </h4>
                      <p className="mt-2 text-gray-600 text-lg  ">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowWeWorkSection;
