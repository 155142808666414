import React, { useState } from "react";
import Navbar from "../components/Navbar";
import blog1 from "../assests/Blog1/Blog1.png";
import blog2 from "../assests/Blog2/Blog2.png";
import blog3 from "../assests/Blog3/Blog3.png";
import blog4 from "../assests/Blog4/Blog4.png";
import { FaArrowRightLong } from "react-icons/fa6";
import DemoCallSection from "../components/LandingPage/DemoCallSection";
import FooterSection from "../components/FooterSection";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const categories = [
    "All",
    "Design",
    "Engineering",
    "ML & AI",
    "Quality Assurance",
    "Security",
    "Infrastructure & DevOps",
  ];

  const blogData = [
    {
      id: 1,
      image: blog1,
      date: "Jun 27, 2024",
      author: "",
      // category: "Engineering",
      title: "How to Hire a Great Team to Build your Digital Product",
      excerpt:
        "Feasto streamlines online retail operations, providing stunning, customizable templates that attract customers...",
      path: "/blog/How-to-Hire-a-Great-Team-to-Build-your-Digital-Product",
    },
    {
      id: 2,
      image: blog2,
      date: "Aug 30, 2024",
      author: "",
      // category: "Design",
      title:
        "7 Important but underrated skills for beginner software developers",
      excerpt:
        "When you think of exploratory testing, do you picture a tester clicking through the app or analyzing requirements? I bet most would...",
      path: "/blog/Exploratory-Testing-in-Requirement-Analysis",
    },
    {
      id: 3,
      image: blog3,
      date: "Sep 13, 2024",
      author: "",
      // category: "ML & AI",
      title: "Getting Started with Machine Learning: A Beginner's Guide",
      excerpt:
        "Design thinking isn’t just a buzzword. It’s a powerful approach that empowers you to tackle complex challenges by putting...",
      path: "/blog/Why-Does-Your-Project-Need-Design-Thinking-and-How-to-Implement-It",
    },
    {
      id: 4,
      image: blog4,
      date: "Nov 15, 2024",
      author: "",
      // category: "Quality Assurance",
      title: "Best Practices for Test Automation in 2024",
      excerpt:
        "Imagine a world where technology works like a smart assistant, making our jobs easier and faste. That’s the power of...",
      path: "/blog/How-LLMs-Can-Change-the-Way-Businesses-Work?",
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState("All");

  const filteredBlogs = blogData.filter((blog) =>
    selectedCategory === "All" ? true : blog.category === selectedCategory
  );

  const DotPattern = () => (
    <svg
      className="absolute inset-0 w-full h-full z-0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <pattern
          id="dotPattern"
          x="0"
          y="0"
          width="20"
          height="20"
          patternUnits="userSpaceOnUse"
        >
          <circle cx="1.5" cy="1.5" r="1.5" fill="rgba(255, 255, 255, 0.1)" />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill="url(#dotPattern)" />
    </svg>
  );

  const navigate = useNavigate();

  const handleNavigate = (path) => {
    window.open(path, "_blank", "noopener noreferrer");
    // navigate(path);
  };

  return (
    <>
      <Helmet>
        <title>Blogs | Latest Tech Insights and Updates by Cogntix</title>
        <meta
          name="description"
          content="Stay updated with the latest news, updates, and tools. Explore the Cogntix blog for valuable insights and resources."
        />
        <meta
          name="keywords"
          content="Cogntix blogs, Tech blog, Software trends, Latest tech news, Software development tips"
        />
      </Helmet>
      <Navbar />
      <div className="w-full bg-primary relative">
        <DotPattern />
        <div className="flex flex-col mt-[100px] lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] py-16 px-2 relative z-10">
          <h2 className="semiHeading text-left">BLOGS</h2>
          <h1 className="lg:text-6xl text-[32px] text-white font-bold mb-6 w-[95%] md:max-w-[900px]">
            Hub for Software & Tech Insights
          </h1>
          <p className="max-w-[900px] whiteParagraph mb-10">
            Discover the most up-to-date insights in software development, the
            latest tech trends, and cutting-edge solutions that will help you
            stay ahead in a rapidly changing industry.
          </p>
        </div>
      </div>
      <div className="flex flex-col lg:pt-[100px] lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] lg:pb-[0px] py-16 px-3 relative z-20">
        {/* <div className="flex flex-wrap gap-3 justify-center w-full mb-10">
          {categories.map((category) => (
            <div
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`${
                selectedCategory === category
                  ? "bg-primary text-white"
                  : "bg-gray-200 text-primary"
              } group inline-flex justify-center items-center rounded-3xl py-1 px-4 text-sm whitespace-nowrap cursor-pointer select-text transition-colors duration-300 hover:bg-primary hover:text-white`}
            >
              {category}
            </div>
          ))}
        </div> */}
        <div className="mt-10 grid w-full lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
          {filteredBlogs.map((blog) => (
            <div
              onClick={() => handleNavigate(blog.path)}
              key={blog.id}
              className="group cursor-pointer relative bg-white group-hover:bg-gray-50 rounded-xl overflow-hidden"
            >
              <div className="overflow-hidden relative">
                <img src={blog.image} alt={blog.title} className="w-full" />
                {/* <div className="absolute top-4 left-4">
                  <span className="text-sm bg-primary text-white px-3 py-1 rounded-full">
                    {blog.category}
                  </span>
                </div> */}
              </div>
              <div className="flex flex-col items-start p-2 pt-4 transition-all duration-300">
                <h3 className="text-sm text-gray-500 font-medium transition-colors duration-300 group-hover:text-primary">
                  {blog.date}
                </h3>
                <h2 className="text-xl text-primary font-semibold mb-4 max-w-[390px]">
                  {blog.title}
                </h2>
                <p className="text-gray-400 mb-4 max-w-[370px] transition-colors duration-300 group-hover:text-gray-600">
                  {blog.excerpt}
                </p>
                <button className="mb-2 flex flex-row gap-2 items-center justify-center text-lg text-secondary font-medium group/button">
                  <p className="transition-transform duration-300 group-hover/button:translate-x-1">
                    Read more
                  </p>
                  <FaArrowRightLong className="mt-1 opacity-0 -translate-x-2 transition-all duration-300 group-hover:opacity-100 group-hover:translate-x-0" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <DemoCallSection />
      <FooterSection />
    </>
  );
};

export default Blogs;
