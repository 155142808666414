import React from "react";
import Navbar from "../components/Navbar";
import Benefits from "../components/Careers/Benefits";
import TeamImages from "../components/Careers/TeamImages";
import Values from "../components/Careers/Values";
import Team from "../components/Careers/Team";
import CurrentOpeningsCard from "../components/Careers/CurrentOpeningsCard";
import FooterSection from "../components/FooterSection";
import { Helmet } from "react-helmet";

const Careers = () => {
  const DotPattern = () => (
    <svg
      className="absolute inset-0 w-full h-full z-0"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <pattern
          id="dotPattern"
          x="0"
          y="0"
          width="20"
          height="20"
          patternUnits="userSpaceOnUse"
        >
          <circle cx="1.5" cy="1.5" r="1.5" fill="rgba(255, 255, 255, 0.1)" />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill="url(#dotPattern)" />
    </svg>
  );
  return (
    <>
      <Helmet>
        <title>Career at Cogntix</title>
        <meta
          name="description"
          content="Explore exciting career opportunities at Cogntix. We're looking for talented individuals ready to make an impact. Start your journey with us!"
        />
        <meta
          name="keywords"
          content="Cogntix jobs, Join a software team, Tech jobs at Cogntix, Work with Cogntix, Internships, Join our team"
        />
      </Helmet>
      <Navbar />
      <div className="w-full bg-primary relative">
        <DotPattern />
        <div className="flex flex-col mt-[100px] lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] py-16 px-2 relative z-10">
          <h2 className="semiHeading text-left">CAREERS</h2>
          <h1 className="lg:text-6xl text-[32px] text-white font-bold mb-6 w-[95%] md:max-w-[800px]">
            Make Great Work with Great People
          </h1>
          <p className="max-w-[900px] whiteParagraph mb-10">
            Join our diverse team to challenge the status quo and craft
            brilliance together.
          </p>
        </div>
      </div>
      <Benefits />
      <TeamImages />
      <Values />
      <Team />
      <CurrentOpeningsCard />
      <FooterSection />
    </>
  );
};

export default Careers;
