import React, { useState, useRef, useEffect } from "react";
import logo from "../assests/LOGO.svg";
import { IoIosArrowDown } from "react-icons/io";
import { HiMiniBars3 } from "react-icons/hi2";
import { HiMiniXMark } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isMobileServicesOpen, setIsMobileServicesOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isTransparent, setIsTransparent] = useState(false);

  const dropdownRef = useRef(null);
  const mobileMenuRef = useRef(null);

  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
    setIsMobileMenuOpen(false);
    setIsServicesOpen(false);
    setIsMobileServicesOpen(false);
  };

  useEffect(() => {
    const controlNavbar = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < lastScrollY) {
        setIsVisible(true);
        setIsTransparent(true);
      } else if (currentScrollY > lastScrollY && currentScrollY > 100) {
        setIsVisible(false);
        setIsTransparent(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", controlNavbar);
    return () => window.removeEventListener("scroll", controlNavbar);
  }, [lastScrollY]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsServicesOpen(false);
      }
      if (
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target) &&
        !event.target.closest(".mobile-menu-button")
      ) {
        setIsMobileMenuOpen(false);
        setIsMobileServicesOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleServicesClick = (event) => {
    event.stopPropagation();
    setIsServicesOpen(!isServicesOpen);
  };

  const handleMobileServicesClick = (event) => {
    event.stopPropagation();
    setIsMobileServicesOpen(!isMobileServicesOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    if (isMobileServicesOpen) setIsMobileServicesOpen(false);
  };

  return (
    <div
      className={`w-full h-[100px] border-gray-300 lg:border-b-[1px] backdrop-blur-md py-[25px] px-4 lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] flex flex-row items-center justify-between fixed top-0 left-0 right-0 z-50 transition-all duration-300 ease-in-out ${
        isVisible ? "translate-y-0" : "-translate-y-full"
      } ${isTransparent ? "bg-[#ffffff40]" : "bg-white"}`}
    >
      <div className="flex items-center gap-4 lg:gap-12">
        <button
          className="lg:hidden mobile-menu-button text-2xl text-gray-400"
          onClick={toggleMobileMenu}
        >
          {isMobileMenuOpen ? <HiMiniXMark /> : <HiMiniBars3 />}
        </button>

        <div>
          <a href="/">
            <img
              className="lg:w-[180px] lg:h-[40px] sm:w-[135px] sm:h-[30px]"
              src={logo}
              alt="logo"
            />
          </a>
        </div>

        {/* Desktop Navigation */}
        <nav className="hidden lg:flex items-center gap-10 cursor-pointer">
          <p
            onClick={() => handleNavigate("/ourWorks")}
            className="font-inter text-base text-text1 hover:text-secondary transition-colors"
          >
            Our works
          </p>
          <div className="relative" ref={dropdownRef}>
            <button
              className="flex items-center gap-1 font-inter text-base text-text1 hover:text-secondary transition-colors"
              onClick={handleServicesClick}
            >
              Services
              <IoIosArrowDown
                className={`transition-transform ${
                  isServicesOpen ? "rotate-180" : ""
                }`}
              />
            </button>
            {isServicesOpen && (
              <div className="absolute top-full left-0 mt-10 w-48 bg-white rounded-lg shadow-lg py-2 z-10">
                <p
                  onClick={() => handleNavigate("/services/productengineering")}
                  className="block px-4 py-2 font-inter text-base text-text1 hover:bg-gray-100 transition-colors"
                >
                  Product Engineering
                </p>
                <p
                  onClick={() => handleNavigate("/services/productdesign")}
                  className="block px-4 py-2 font-inter text-base text-text1 hover:bg-gray-100 transition-colors"
                >
                  Product Design
                </p>
              </div>
            )}
          </div>
          <p
            onClick={() => handleNavigate("/subscriptions")}
            className="font-inter text-base text-text1 hover:text-secondary transition-colors"
          >
            Subscriptions
          </p>
          <p
            onClick={() => handleNavigate("/blogs")}
            className="font-inter text-base text-text1 hover:text-secondary transition-colors"
          >
            Blogs
          </p>
        </nav>
      </div>

      <div>
        <a
          href="https://cal.com/naresh-shanmugaraj/discussion"
          target="_blank"
          rel="noopener noreferrer"
          className="lg:h-[52px] lg:w-[119px] rounded-lg bg-primary hover:text-secondary text-white font-inter lg:text-base lg:font-semibold
         lg:px-[25px] lg:py-[14px] px-[16px] py-[7px]"
        >
          Let's talk
        </a>
      </div>

      {/* Mobile Navigation Menu */}
      {isMobileMenuOpen && (
        <div
          ref={mobileMenuRef}
          className="absolute top-[100px] left-0 w-full bg-white backdrop-blur-md shadow-lg z-20"
        >
          <div className="px-4 py-4 flex flex-col gap-4">
            <p
              onClick={() => handleNavigate("/")}
              className="font-inter text-base text-text1 hover:text-secondary transition-colors py-2"
            >
              Home
            </p>
            <p
              onClick={() => handleNavigate("/ourWorks")}
              className="font-inter text-base text-text1 hover:text-secondary transition-colors py-2"
            >
              Our works
            </p>

            {/* Mobile Services Menu */}
            <div className="flex flex-col">
              <div
                onClick={handleMobileServicesClick}
                className="flex items-center justify-between font-inter text-base text-text1 hover:text-secondary transition-colors py-2 cursor-pointer"
              >
                <span>Services</span>
                <IoIosArrowDown
                  className={`transition-transform ${
                    isMobileServicesOpen ? "rotate-180" : ""
                  }`}
                />
              </div>
            </div>

            {/* Separate Mobile Services Dropdown */}
            {isMobileServicesOpen && (
              <div className="ml-4 flex flex-col gap-2">
                <div
                  className="font-inter text-base text-text1 hover:text-secondary transition-colors py-2 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNavigate("/services/productengineering");
                  }}
                >
                  Product Engineering
                </div>
                <div
                  className="font-inter text-base text-text1 hover:text-secondary transition-colors py-2 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNavigate("/services/productdesign");
                  }}
                >
                  Product Design
                </div>
              </div>
            )}

            <p
              onClick={() => handleNavigate("/subscriptions")}
              className="font-inter text-base text-text1 hover:text-secondary transition-colors py-2"
            >
              Subscriptions
            </p>
            <p
              onClick={() => handleNavigate("/blogs")}
              className="font-inter text-base text-text1 hover:text-secondary transition-colors py-2"
            >
              Blogs
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
