import React from "react";
import { HiUserGroup } from "react-icons/hi";
import { FaClock } from "react-icons/fa6";
import { FaFlag } from "react-icons/fa6";
import { FaCircleCheck } from "react-icons/fa6";
import { BsFillBarChartFill } from "react-icons/bs";
import { FaBuilding } from "react-icons/fa";

const Benefits = () => {
  const BenefitsData = [
    {
      icon: FaClock,
      title: "Flexible working hours",
      description:
        "Enjoy the flexibility to work remotely or in the office with hours that fit your schedule.",
    },
    {
      icon: BsFillBarChartFill,
      title: "Career Growth",
      description:
        "With multiple projects running, you’ll have constant opportunities to learn, grow, and excel in your career.",
    },
    {
      icon: FaBuilding,
      title: "Work-Life Balance",
      description:
        "We prioritize healthy work-life balance with reasonable deadlines, limited overtime & encouraging time off.",
    },
    {
      icon: HiUserGroup,
      title: "Cultural diversity",
      description:
        "We nurture a culture that celebrates individuality and supports career advancement.",
    },
    {
      icon: FaFlag,
      title: "Team retreats",
      description:
        "Enjoy team retreats that foster collaboration, creativity and strengthening bonds.",
    },
    {
      icon: FaCircleCheck,
      title: "Paid vacation",
      description:
        "Enjoy paid time off to rest and recharge, ensuring you return refreshed and ready to do your best work.",
    },
  ];

  return (
    <div className="flex flex-col lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] py-16 px-3">
      <div className="flex flex-col justify-center">
        <h2 className="semiHeading text-center">Perks & Benefits</h2>
        <h2 className="heading1 mb-8 text-center">What makes Us Different</h2>
        <p className="text-center text-text2 text-lg mb-16">
          Experience a culture that values your growth, celebrates achievements,
          and supports personal and professional thriving.
        </p>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-6">
        {BenefitsData.map((feature, index) => {
          const Icon = feature.icon;
          return (
            <div
              key={index}
              className="relative border-[1px] border-gray-200 rounded-2xl p-6 flex flex-col items-start 
                        overflow-hidden cursor-pointer group
                        transition-all duration-500 ease-in-out
                        hover:shadow-2xl transform hover:scale-105
                        before:absolute before:inset-0 
                        before:opacity-0 before:transition-opacity before:duration-500
                        hover:before:opacity-5"
            >
              {/* Animated border effect */}
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                <div className="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-secondary to-transparent animate-shimmer"></div>
                <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-secondary to-transparent animate-shimmer"></div>
                <div className="absolute inset-y-0 left-0 w-px bg-gradient-to-b from-transparent via-secondary to-transparent animate-shimmer"></div>
                <div className="absolute inset-y-0 right-0 w-px bg-gradient-to-b from-transparent via-secondary to-transparent animate-shimmer"></div>
              </div>

              <Icon
                className="size-10 text-secondary mb-9 relative z-10
                             transition-all duration-500 ease-in-out
                             group-hover:scale-110"
              />

              <h3
                className="text-black text-lg font-bold mb-4 relative z-10
                          transition-all duration-500
                          group-hover:text-secondary group-hover:translate-x-2 capitalize"
              >
                {feature.title}
              </h3>

              <p
                className="text-black text-lg relative z-10
                          transition-all duration-500 group-hover:text-gray-700"
              >
                {feature.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styleTag = document.createElement("style");
styleTag.textContent = `
  @keyframes shimmer {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  .animate-shimmer {
    animation: shimmer 2s infinite;
  }
`;
document.head.appendChild(styleTag);

export default Benefits;
