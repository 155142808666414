import axiosInstance from "./index";

export const uploadFormData = async (formData) => {
  try {
    const response = await axiosInstance.post("", formData);
    return response.data;
  } catch (error) {
    console.error("Error uploading form data:", error);
    throw error;
  }
};
