import React from "react";
import project1 from "../../assests/Project1/Project1.png";
import project2 from "../../assests/Project2/Project2.png";
import project3 from "../../assests/Project3/Project3.png";
import project4 from "../../assests/Project4/Project4.png";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const Projects = () => {
  const navigate = useNavigate();

  const handleViewMoreClick = () => {
    navigate("/ourWorks");
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <>
      <div className="bg-white md:px-24 larger:px-[200px] mx-auto max-w-[2000px] px-2 md:mt-20 mt-10">
        <div className="flex flex-col justify-center">
          <h2 className="semiHeading text-center">OUR WORKS</h2>
          <h2 className="heading1 mb-8 text-center">
            Our work speaks for itself
          </h2>
          <p className="text-center text-text2 text-lg mb-16">
            Discover how we transform ideas into successful solutions.
          </p>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-10">
          <div
            onClick={() => handleNavigate("/ourworks/Feasto")}
            className="w-full group cursor-pointer "
          >
            <div className="overflow-hidden rounded-[25px] mb-8">
              <img
                src={project1}
                alt="project1"
                className="w-full h-auto object-cover bg-white transition-transform duration-500 group-hover:scale-110"
              />
            </div>
            <p className="text-lg text-text3 font-medium transition-colors duration-300 group-hover:text-secondary">
              Ideation, UI/UX design, Product development, Support
            </p>
            <h2 className="text-[32px] text-primary font-bold group-hover:underline">
              FEASTO - Web Store Builder for Small Businesses
            </h2>
          </div>

          <div
            onClick={() => handleNavigate("/ourworks/Recipelab")}
            className="w-full group cursor-pointer "
          >
            <div className="overflow-hidden rounded-[25px] mb-8">
              <img
                src={project2}
                alt="project2"
                className="w-full h-auto object-cover bg-white transition-transform duration-500 group-hover:scale-110"
              />
            </div>
            <p className="text-lg text-text3 font-medium transition-colors duration-300 group-hover:text-secondary">
              Market Research, Ideation, UI/UX design
            </p>
            <h2 className="text-[32px] text-primary font-bold group-hover:underline">
              Recipe Lab - The Chef's Digital Companion
            </h2>
          </div>

          <div
            onClick={() =>
              handleNavigate("/ourworks/AI-Powered-Query-Platform")
            }
            className="w-full group cursor-pointer "
          >
            <div className="overflow-hidden rounded-[25px] mb-8">
              <img
                src={project3}
                alt="project3"
                className="w-full h-auto object-cover bg-white transition-transform duration-500 group-hover:scale-110"
              />
            </div>
            <p className="text-lg text-text3 font-medium transition-colors duration-300 group-hover:text-secondary">
              Ideation, R&D, UI/UX design
            </p>
            <h2 className="text-[32px] text-primary font-bold group-hover:underline">
              AI-Powered Query Platform for Construction Company
            </h2>
          </div>

          <div
            onClick={() => handleNavigate("/ourworks/Milta")}
            className="w-full group cursor-pointer "
          >
            <div className="overflow-hidden rounded-[25px] mb-8">
              <img
                src={project4}
                alt="project4"
                className="w-full h-auto object-cover bg-white transition-transform duration-500 group-hover:scale-110"
              />
            </div>
            <p className="text-lg text-text3 font-medium transition-colors duration-300 group-hover:text-secondary">
              UI/UX design, Product development
            </p>
            <h2 className="text-[32px] text-primary font-bold group-hover:underline">
              MILTA's RMS - Retailers Management Solution for Telecommunication
              Provider
            </h2>
          </div>
        </div>
        <div className="flex justify-center mt-16 mb-16">
          <button
            onClick={handleViewMoreClick}
            className="group px-6 py-2 flex flex-row gap-3 items-center justify-center bg-white rounded-lg border-primary border-[1px] min-w-[250px]"
          >
            <p className="font-medium text-primary text-lg">View More Works</p>
            <FaArrowRightLong className="size-5 group-hover:inline hidden transition-all" />
          </button>
        </div>
      </div>
    </>
  );
};

export default Projects;
