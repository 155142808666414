import React from "react";
import Navbar from "../components/Navbar";
import AllProjects from "../components/OurWorks/Allprojects";
import Testimonials from "../components/LandingPage/Testimonials";
import DemoCallSection from "../components/LandingPage/DemoCallSection";
import FooterSection from "../components/FooterSection";
import { Helmet } from "react-helmet";

const OurWorksMainPage = () => {
  const DotPattern = () => (
    <svg
      className="absolute inset-0 w-full h-full"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <pattern
          id="dotPattern"
          x="0"
          y="0"
          width="20"
          height="20"
          patternUnits="userSpaceOnUse"
        >
          <circle cx="1.5" cy="1.5" r="1.5" fill="rgba(255, 255, 255, 0.1)" />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill="url(#dotPattern)" />
    </svg>
  );

  return (
    <>
      <Helmet>
        <title>Our Works | Projects by Cogntix</title>
        <meta
          name="description"
          content="Explore how our software solutions have helped businesses grow and succeed by exploring our portfolio of real-world projects. "
        />
        <meta
          name="keywords"
          content="Cogntix portfolio, software case studies, Cogntix case study"
        />
      </Helmet>
      <Navbar />
      <div className="w-full bg-primary">
        <DotPattern />
        <div className="flex flex-col mt-[100px] lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] py-16 px-2">
          <h2 className="semiHeading text-left">OUR WORKS</h2>
          <h1 className="lg:text-6xl text-left text-[32px] text-white font-bold mb-6 w-[95%] md:max-w-[900px]">
            Discover our works which express our capabilities
          </h1>
          <p className="max-w-[900px] whiteParagraph mb-10">
            We will work together with you to create meaningful and impactful
            products for your users. Here are some success stories of our top
            engagements from all over the world.
          </p>
        </div>
      </div>
      <AllProjects />
      <Testimonials />
      <DemoCallSection />
      <FooterSection />
    </>
  );
};

export default OurWorksMainPage;
