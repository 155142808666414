import React from "react";
import project1 from "../../assests/Project1/Project1.png";
import project2 from "../../assests/Project2/Project2.png";
import project3 from "../../assests/Project3/Project3.png";
import project4 from "../../assests/Project4/Project4.png";
import project5 from "../../assests/Project5/Project5.png";
import project6 from "../../assests/Project6/Project6.png";
import project7 from "../../assests/Project7/Project7.png";

const projectsData = [
  {
    id: 1,
    image: project1,
    path: "/ourworks/Feasto",
    categories: "Ideation, UI/UX design, Product development, Support",
    title: "FEASTO - Web Store Builder for Small Businesses",
  },
  {
    id: 2,
    image: project2,
    path: "/ourworks/Recipelab",
    categories: "Market Research, Ideation, UI/UX design",
    title: "Recipe Lab - The Chef's Digital Companion",
  },
  {
    id: 3,
    image: project3,
    path: "/ourworks/AI-Powered-Query-Platform",
    categories: "Ideation, R&D, UI/UX design",
    title: "AI-Powered Query Platform for Construction Company",
  },
  {
    id: 4,
    image: project4,
    path: "/ourworks/Milta",
    categories: "UI/UX design, Product development",
    title:
      "MILTA's RMS - Retailers Management Solution for Telecommunication Provider",
  },
  {
    id: 5,
    image: project5,
    path: "/ourworks/Bellize-Accounts-Management-Solution-for-Auditing-Firm",
    categories: "Market Research, Ideation, UI/UX design, Prototyping",
    title: "Bellize - Accounts Management Solution for Audit Firm",
  },
  {
    id: 6,
    image: project6,
    path: "/ourworks/Parcel-Delivery-System",
    categories: "Market Research, Ideation, UI/UX design, Prototyping",
    title:
      "Parcel Delivery Management Platform for Swiss Last-mile Delivery Service",
  },
  {
    id: 7,
    image: project7,
    path: "/ourworks/Customers-Bill-Management",
    categories: "Market Research, Ideation, UI/UX design, Prototyping",
    title: "Customers Bills Management App for New Zealand Small Businesses",
  },
];

const ProjectCard = ({ project }) => (
  <a
    href={project.path}
    target="_blank"
    rel="noreferrer"
    className="block no-underline w-full group cursor-pointer"
  >
    <div className="overflow-hidden rounded-[25px] mb-8">
      <img
        src={project.image}
        alt={`project${project.id}`}
        className="w-full h-auto object-cover bg-white transition-transform duration-500 group-hover:scale-110"
      />
    </div>
    <p className="text-lg text-text3 font-medium transition-colors duration-300 group-hover:text-secondary">
      {project.categories}
    </p>
    <h2 className="text-[32px] text-primary font-bold group-hover:underline">
      {project.title}
    </h2>
  </a>
);

const AllProjects = () => {
  return (
    <div className="bg-white md:px-24 larger:px-[200px] mx-auto max-w-[2000px] px-2 md:mt-20 mt-10 lg:mb-16 mb-10">
      <div className="grid md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-10">
        {projectsData.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
    </div>
  );
};

export default AllProjects;
