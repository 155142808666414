import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ClutchWidget = () => {
  const location = useLocation();
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  const initializeWidget = () => {
    // Clear existing widget containers
    const existingWidgets = document.querySelectorAll('.clutch-widget iframe');
    existingWidgets.forEach(widget => widget.remove());

    // Reinitialize Clutch widget
    if (window.CLUTCHCO) {
      window.CLUTCHCO.Init();
    }
  };

  useEffect(() => {
    let scriptElement = document.querySelector('script[src*="widget.clutch.co"]');
    
    const loadScript = () => {
      if (scriptElement) {
        setIsScriptLoaded(true);
        initializeWidget();
        return;
      }

      const script = document.createElement("script");
      script.src = "https://widget.clutch.co/static/js/widget.js";
      script.async = true;
      script.type = "text/javascript";
      
      script.onload = () => {
        setIsScriptLoaded(true);
        initializeWidget();
      };

      script.onerror = () => {
        if (retryCount < maxRetries) {
          setRetryCount(prev => prev + 1);
          script.remove();
        }
      };

      document.body.appendChild(script);
      scriptElement = script;
    };

    loadScript();

    return () => {
      if (scriptElement && retryCount === maxRetries) {
        scriptElement.remove();
      }
    };
  }, [retryCount]);

  // Reinitialize widget on route change
  useEffect(() => {
    if (isScriptLoaded) {
      // Add a small delay to ensure DOM is ready
      const timer = setTimeout(() => {
        initializeWidget();
      }, 100);
      
      return () => clearTimeout(timer);
    }
  }, [location.pathname, isScriptLoaded]);

  // If script fails to load after max retries, show fallback
  if (retryCount === maxRetries && !isScriptLoaded) {
    return (
      <div className="clutch-widget-fallback bg-white max-w-[200px] rounded-lg p-1 border border-gray-200">
        <a 
          href="https://clutch.co" 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-sm text-gray-600 hover:text-gray-800"
        >
          View our Clutch Reviews
        </a>
      </div>
    );
  }

  return (
    <div
      className={`clutch-widget bg-white max-w-[210px] shadow-md rounded-lg p-2 border border-gray-200 ${
        isScriptLoaded ? 'opacity-100' : 'opacity-0'
      } transition-opacity duration-300`}
      data-url="https://widget.clutch.co"
      data-widget-type="2"
      data-height="45"
      data-nofollow="true"
      data-expandifr="true"
      data-scale="100"
      data-clutchcompany-id="2420062"
    />
  );
};

export default ClutchWidget;