import axios from "axios";
import { SERVICE_BASE_URL, API_HEADER_TOEKN } from "../utils/config";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: SERVICE_BASE_URL, // Base URL from .env file
  headers: {
    "Content-Type": "application/json",
    "x-api-key": API_HEADER_TOEKN
  },
});

// Add interceptors (optional, for token management or logging)
axiosInstance.interceptors.request.use(
  (config) => {
    // You can modify request config here, e.g., add auth token
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally
    console.error("API Error:", error);
    return Promise.reject(error);
  }
);

export default axiosInstance;
