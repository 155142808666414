import React from "react";
import Navbar from "../components/Navbar";
import FooterSection from "../components/FooterSection";
import { Helmet } from "react-helmet";

const TermsandConditions = () => {
  return (
    <>
      <Helmet>
        <title>Terms & Conditions</title>
        <meta
          name="description"
          content="Explore our terms and conditions page to understand the rules, rights and responsibilities of using our website. Your legal agreement with us starts here."
        />
        <meta name="keywords" content="Terms and conditions, Cogntix terms and conditions, user agreement, Terms of service, Usage guidelines" />
      </Helmet>
      <Navbar />
      <div className="lg:mt-[140px] mt-[120px] lg:px-[100px] larger:px-[200px] larger:mx-auto max-w-[2000px] mx-2 flex flex-col items-start">
        <h1 className="heading1 mb-6">Terms & conditions</h1>
        <p className="text-gray-600 text-lg mb-10">
          Last updated: October 31, 2024
        </p>
      </div>
      <div className="lg:px-[100px] larger:px-[200px] lg:mx-auto max-w-[2000px] mx-2 mb-20 flex flex-col gap-10 items-start">
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            1. Acceptance of Terms
          </h2>
          <p className="text-gray-600">
            By using Cogntix’s website, software, or related services, you agree
            to be bound by these Terms and Conditions, our Privacy Policy, and
            any other policies or terms referred to within this document. If you
            do not agree, please do not use our services.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            2. Services Provided
          </h2>
          <p className="text-gray-600">
            Cogntix provides software development, consulting, and support
            services. Details of the services will be specified in individual
            contracts or agreements, including scope, duration, fees, and
            specific deliverables.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            3. Use of Services
          </h2>
          <p className="text-gray-600 mb-6">
            You agree to use Cogntix services only for lawful purposes. You
            shall not engage in activities that:
          </p>
          <ul className="list-disc pl-5 mb-4">
            <li className="text-gray-600">
              Violate any applicable law or regulation.
            </li>
            <li className="text-gray-600">
              Infringe upon or violate Cogntix’s or third parties' intellectual
              property rights.
            </li>
            <li className="text-gray-600">
              Disrupt or damage Cogntix’s systems or those of other users.
            </li>
          </ul>
          <p className="text-gray-600">
            Any misuse may result in suspension or termination of access to our
            services.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            4. Intellectual Property
          </h2>
          <p className="text-gray-600">
            All intellectual property rights, including but not limited to
            software code, design, content, and trademarks, remain the exclusive
            property of Cogntix or its licensors. You may not copy, modify,
            distribute, or create derivative works based on Cogntix’s
            intellectual property without prior written permission.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            5. User Content and Data
          </h2>
          <p className="text-gray-600 mb-6">
            When using our services, you may provide content or data (“User
            Content”) to Cogntix. You retain ownership of your User Content, but
            by using our services, you grant Cogntix a non-exclusive, worldwide,
            royalty-free license to use, store, and process your User Content as
            necessary to provide our services.
          </p>
          <p className="text-gray-600">
            Cogntix will implement appropriate security measures to protect User
            Content but cannot guarantee absolute security against unauthorized
            access or disclosure.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            6. Fees and Payments
          </h2>
          <p className="text-gray-600 mb-6">
            Fees for Cogntix services are specified in individual agreements.
            Payment terms are as follows:
          </p>
          <ul className="list-disc pl-5 mb-6">
            <li className="text-gray-600">
              Invoices are due within the payment period specified in the
              contract.
            </li>
            <li className="text-gray-600">
              Late payments may be subject to interest or suspension of services
              until payment is made.
            </li>
          </ul>
          <p className="text-gray-600">
            Additional fees may apply if services outside the agreed-upon scope
            are requested.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            7. Confidentiality
          </h2>
          <p className="text-gray-600">
            Cogntix and the client agree to maintain the confidentiality of any
            proprietary or sensitive information shared during the term of the
            agreement. Confidential information includes, but is not limited to,
            trade secrets, business strategies, and client data.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            8. Data Protection and Privacy
          </h2>
          <p className="text-gray-600">
            Cogntix is committed to protecting your privacy in accordance with
            our Privacy Policy, which complies with Sri Lankan data protection
            laws and the EU GDPR, if applicable. By using our services, you
            consent to the collection, processing, and use of your data as
            described in the Privacy Policy.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            9. Limitation of Liability
          </h2>
          <p className="text-gray-600">
            To the extent permitted by law, Cogntix is not liable for any
            indirect, incidental, or consequential damages, including lost
            profits or data, resulting from the use of or inability to use our
            services. Cogntix’s total liability for any claims related to our
            services is limited to the fees paid by the client for the specific
            service giving rise to the claim.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            10. Indemnification
          </h2>
          <p className="text-gray-600">
            You agree to indemnify and hold harmless Cogntix, its employees, and
            agents from any claims, liabilities, damages, or expenses arising
            out of your use of our services, violation of these Terms and
            Conditions, or infringement of any intellectual property rights.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            11. Termination
          </h2>
          <p className="text-gray-600 mb-6">
            Cogntix reserves the right to terminate or suspend access to
            services for violation of these Terms and Conditions or any
            applicable laws. Either party may terminate the service agreement by
            providing written notice in accordance with the contract terms.
          </p>
          <p className="text-gray-600">
            Upon termination, you must cease all use of Cogntix’s services and
            delete any proprietary software or documentation provided by
            Cogntix.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            12. Changes to Terms and Conditions
          </h2>
          <p className="text-gray-600">
            Cogntix reserves the right to modify these Terms and Conditions as
            necessary. Any changes will be posted on this page, and continued
            use of our services after such changes constitutes acceptance of the
            modified terms.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            13. Governing Law and Dispute Resolution
          </h2>
          <p className="text-gray-600">
            These Terms and Conditions are governed by the laws of Sri Lanka.
            Any disputes arising out of or related to these Terms will be
            resolved through mediation or arbitration in Sri Lanka, unless
            otherwise specified in the contract.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            14. Contact Information
          </h2>
          <p className="text-gray-600 mb-6">
            For any questions or concerns regarding these Terms and Conditions,
            please contact us at:
          </p>
          <ul className="list-none">
            <li className="text-gray-600">
              <span className="font-bold">Email: </span>
              <a
                href="mailto:hai@cogntix.com"
                className="text-primary font-medium"
              >
                hai@cogntix.com
              </a>
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Address: </span>No 218, Stanley Road,
              Jaffna, Sri Lanka.
            </li>
          </ul>
        </section>
      </div>
      <FooterSection />
    </>
  );
};

export default TermsandConditions;
