import React from "react";
import { IoLayers } from "react-icons/io5";
import { MdOutlineWeb } from "react-icons/md";
import { FaMobileAlt } from "react-icons/fa";
import { GrCloudComputer } from "react-icons/gr";
import { MdFactCheck } from "react-icons/md";

export function FluentBrainCircuit24Filled(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14px"
      height="14px"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="white"
        d="M10.14 2.006c.707 0 1.11.704 1.11 1.411V7.75h-.895a2 2 0 1 0 0 1.5h.895v10.898c0 .596-.252 1.189-.783 1.46a3.4 3.4 0 0 1-1.56.388c-1.51 0-2.633-.764-3.356-1.668a5.2 5.2 0 0 1-1.02-2.137a3.45 3.45 0 0 1-1.269-.69C2.552 16.89 2 15.91 2 14.466c0-.755.054-1.413.19-1.966H6.4c.678 0 1.24.5 1.335 1.151a2 2 0 1 0 1.507-.009A2.85 2.85 0 0 0 6.4 11H3.04a2 2 0 0 1 .495-.302a5 5 0 0 1-.178-1.127c-.033-.735.077-1.5.295-2.181c.216-.67.558-1.323 1.038-1.774a2.1 2.1 0 0 1 1.096-.567c.199-.84.706-1.534 1.353-2.037c.831-.648 1.92-1.006 3-1.006M12.75 17h1.65a2.85 2.85 0 0 0 2.85-2.85v-1.795a2 2 0 1 0-1.5 0v1.795a1.35 1.35 0 0 1-1.35 1.35h-1.65V3.417c0-.707.403-1.411 1.11-1.411c1.082 0 2.17.358 3.001 1.006c.647.503 1.154 1.198 1.353 2.037c.42.07.794.284 1.096.567c.48.451.822 1.103 1.038 1.774c.218.681.328 1.446.295 2.181c-.017.376-.072.76-.178 1.127l.066.03c.37.174.67.447.894.81c.425.685.575 1.671.575 2.928c0 1.445-.552 2.426-1.262 3.035a3.45 3.45 0 0 1-1.27.69a5.2 5.2 0 0 1-1.019 2.137c-.723.904-1.846 1.668-3.357 1.668a3.4 3.4 0 0 1-1.56-.387c-.53-.272-.782-.865-.782-1.461zM8 8.5a.5.5 0 1 1 1 0a.5.5 0 0 1-1 0m.5 6.5a.5.5 0 1 0 0 1a.5.5 0 0 0 0-1m7.5-4.5a.5.5 0 1 0 1 0a.5.5 0 0 0-1 0"
      ></path>
    </svg>
  );
}

const FeaturesEngineering = () => {
  const FeaturesEngineeringData = [
    {
      icon: IoLayers,
      title: "Custom Web Development",
      description:
        "Tailored websites built with the latest technologies to meet unique business needs and optimize user experience.",
    },
    {
      icon: MdOutlineWeb,
      title: "SaaS Application Development",
      description:
        "Full-cycle SaaS development services to build scalable, cloud-based solutions that drive revenue and user retention.",
    },
    {
      icon: FaMobileAlt,
      title: "Mobile Application Development",
      description:
        "End-to-end mobile app development for iOS and Android, focusing on seamless performance and a mobile-first.",
    },
    {
      icon: GrCloudComputer,
      title: "API Integration and Development",
      description:
        "Design and implementation of robust APIs to ensure seamless connectivity between software systems.",
    },
    {
      icon: FluentBrainCircuit24Filled,
      title: "AI Application Development",
      description:
        "Integrate AI capabilities for smarter, data-driven applications, improving user experience and automation.",
    },
    {
      icon: MdFactCheck,
      title: "Quality Assurance and Testing",
      description:
        "Comprehensive testing services to ensure your product's functionality, security, and performance.",
    },
  ];

  return (
    <div className="bg-primary">
      <div className="flex flex-col lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] py-16 px-3 bg-primary">
        <div className="flex flex-col justify-center w-full">
          <h2 className="semiHeading text-center">
            PRODUCT ENGINEERING SERVICES
          </h2>
          <h2 className="md:text-6xl text-[30px] text-white font-bold mb-8 text-center md:mx-auto md:w-[80%]">
            Your Vision, Our Expertise
          </h2>
          <p className="text-center text-white text-lg mb-16 lg:mx-auto mx-3 lg:max-w-[880px]">
            The best software solutions are just one step away now with
            Cogntix’s product engineering.
          </p>
        </div>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-6">
          {FeaturesEngineeringData.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <div
                key={index}
                className="relative shadow-md rounded-2xl border-[1px] border-gray-700 p-6 flex flex-col items-start 
                        overflow-hidden cursor-pointer group
                        transition-all duration-500 ease-in-out
                        hover:shadow-2xl transform hover:scale-105
                        before:absolute before:inset-0 
                        before:opacity-0 before:transition-opacity before:duration-500
                        hover:before:opacity-5"
              >
                {/* Animated border effect */}
                <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                  <div className="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-secondary to-transparent animate-shimmer"></div>
                  <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-secondary to-transparent animate-shimmer"></div>
                  <div className="absolute inset-y-0 left-0 w-px bg-gradient-to-b from-transparent via-secondary to-transparent animate-shimmer"></div>
                  <div className="absolute inset-y-0 right-0 w-px bg-gradient-to-b from-transparent via-secondary to-transparent animate-shimmer"></div>
                </div>

                <Icon
                  className="size-10 text-white group-hover:text-secondary mb-9 relative z-10
                             transition-all duration-500 ease-in-out
                             group-hover:scale-110"
                />

                <h3
                  className="text-white text-lg font-bold mb-4 relative z-10
                          transition-all duration-500
                          group-hover:text-secondary group-hover:translate-x-2"
                >
                  {feature.title}
                </h3>

                <p
                  className="text-gray-400 text-lg relative z-10
                          transition-all duration-500 group-hover:text-gray-100"
                >
                  {feature.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const styleTag = document.createElement("style");
styleTag.textContent = `
  @keyframes shimmer {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  .animate-shimmer {
    animation: shimmer 2s infinite;
  }
`;
document.head.appendChild(styleTag);

export default FeaturesEngineering;
