import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { PiCaretLeftBold } from "react-icons/pi";
import projectDetail2 from "../../assests/Project2/ProjectDetail2.png";
import project2Sample1 from "../../assests/Project2/project2sample1.png";
import project2Sample2 from "../../assests/Project2/project2sample2.png";
import project2Sample3 from "../../assests/Project2/project2sample3.png";
import customer from "../../assests/Project2/customer4.png";
import logo from "../../assests/Project2/customerlogo4.png";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import DemoCallSection from "../LandingPage/DemoCallSection";
import FooterSection from "../FooterSection";
import { Helmet } from "react-helmet";

const ImagePreview = ({ isOpen, image, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div className="relative max-w-[90vw] max-h-[90vh]">
        <button
          onClick={onClose}
          className="absolute -top-8 -right-5 text-white hover:text-gray-300"
        >
          <div className="flex justify-center items-center bg-gray-200 rounded-full p-1">
            <IoMdClose className="w-5 h-5 text-gray-600" />
          </div>
        </button>
        <img
          src={image}
          alt="Preview"
          className="max-w-full max-h-[85vh] object-contain"
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

const Project2 = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const tags = ["Market Research", "Ideation", "UI/UX Design"];

  const projectInfo = [
    { label: "Industry", value: "Culinary" },
    { label: "Location", value: "France" },
    { label: "Client", value: "Rama Sunatharasarma" },
    { label: "Time", value: "1 month" },
  ];

  const objectivesList = [
    "A simple tool that lets chefs add or edit recipe steps without hassle.",
    "Offer a step-by-step guide for preparation that chefs can customize for each recipe.",
    "Assist chefs in effortlessly adjusting ingredient quantities according to the desired number of servings.",
    "A place to store and organize ingredient details, such as prices and suppliers.",
  ];

  const projectSamples = [
    { image: project2Sample1, alt: "project2Sample1" },
    { image: project2Sample2, alt: "project2Sample2" },
    { image: project2Sample3, alt: "project2Sample3" },
  ];

  return (
    <>
      <Helmet>
        <title>Recipe Lab | The Chef’s Digital Companion</title>
        <meta
          name="description"
          content="Read how Recipe Lab teamed up with us at Cogntix to create a digital platform that enhances chef productivity by streamlining recipe adjustments, ingredient management, and serving size calculations. "
        />
        <meta
          name="keywords"
          content="Cogntix case studies, Recipe Lab, Culinary, Recipe management platform, Chef recipe, Digital tool for chefs"
        />
      </Helmet>
      <Navbar />
      <div className="bg-white lg:mt-[150px] mt-[100px] lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <div
          onClick={() => handleNavigate("/ourWorks")}
          className="flex felx-row gap-3 items-center group cursor-pointer"
        >
          <PiCaretLeftBold className="text-xl text-gray-500 group-hover:text-secondary" />
          <p className="text-lg text-gray-500 group-hover:text-secondary">
            Back to Our works
          </p>
        </div>
        <div className="justify-start mt-4">
          <img src={logo} alt="logo" />
          <div className="mt-10 flex flex-wrap gap-3 md:w-[40%] w-full">
            {tags.map((tag, index) => (
              <div
                key={index}
                className="bg-gray-200 inline-flex justify-center items-center rounded-3xl py-1 px-4 text-primary font-medium text-sm whitespace-nowrap"
              >
                {tag}
              </div>
            ))}
          </div>
          <h1 className="ourWorkHeading mb-6 mt-4 lg:w-[75%]">
            Recipe Lab - The Chef’s Digital Companion
          </h1>
          <p className="lg:w-[65%] text-text2 text-lg">
            A French-based platform, worked with us to create an easy-to-use
            platform for chefs. This platform helps chefs organize their recipes
            and ingredients, making their work easier and faster.
          </p>
          <div className="mt-12 lg:w-[80%] grid md:grid-cols-4 grid-cols-2 lg:gap-12 gap-8">
            {projectInfo.map((info, index) => (
              <div key={index}>
                <p className="text-sm text-gray-700 uppercase">{info.label}</p>
                <p className="text-xl text-text4 font-semibold font-inter">
                  {info.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full lg:mt-14 mt-10 lg:px-[90px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <img
          src={projectDetail2}
          alt="projectDetail2"
          className="w-full h-full"
        />
      </div>

      <div className="lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2 my-20">
        <div className="flex flex-col gap-20">
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Background</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  Chefs often find it challenging to keep track of recipes,
                  ingredients, and cooking methods. For new chefs, traveling
                  frequently and constantly coming up with fresh ideas can make
                  this even harder. Recipe Lab was designed to solve these
                  issues by giving chefs a simple, all-in-one streamlined
                  digital solution to organize recipes, manage ingredients, and
                  adjust measurements quickly and accurately. With Recipe Lab,
                  chefs can focus more on their art rather than administrative
                  tasks.
                </span>
                <br />
              </p>
            </div>
          </div>

          {/* Objective Section */}
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Objective</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  Recipe Lab’s primary goal was to offer chefs a seamless,
                  all-in-one platform to enhance their productivity and
                  creativity freedom in the kitchen. The focus was to make
                  recipe management simple and efficient.
                </span>
                <br />
                <span>
                  Recipe Lab aimed to make chef’s work easier by providing a
                  platform that allows them to:
                </span>
                <br />
              </p>
              <div className="flex flex-col gap-6 mt-10">
                {objectivesList.map((objective, index) => (
                  <div key={index} className="flex flex-row  gap-3">
                    <div>
                      <FaCheck className="size-6 text-secondary mt-1" />
                    </div>
                    <div>
                      <p className="text-lg text-text2">{objective}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Solution Section - Not using map as requested */}
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Solution</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full text-lg text-text2">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  Cogntix worked closely with Recipe Lab to design a
                  user-friendly platform that helps chefs at every step of their
                  creative process. Recipe Lab’s recipe creation feature allows
                  chefs to enter detailed steps, making it simple to adjust or
                  update recipes as they prefer. The ingredient management
                  system organizes ingredient details in one place including
                  prices and supplier information, so chefs can easily update
                  and check inventory. The platform also includes an automatic
                  calculator that scales ingredient amounts for different
                  serving sizes. This saves chefs time and prevents errors when
                  adjusting recipes. 
                </span>
                <br />
                <span>
                  With Cogntix’s solution, Recipe Lab is revolutionizing the way
                  chefs handle their day to day tasks, streamline processes and
                  enhance their creativity.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col mt-20 w-full gap-5 lg:max-h-[500px]">
        {projectSamples.map((image, index) => (
          <img
            key={index}
            src={image.image}
            alt={image.alt}
            className="w-full cursor-pointer transition-transform hover:scale-[1.02]"
            onClick={() => setSelectedImage(image.image)}
          />
        ))}
      </div>

      <ImagePreview
        isOpen={!!selectedImage}
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
      />

      <div className="bg-gray-100 mt-24 p-5">
        <div className="w-full lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
          <div className="w-[90%] mx-auto rounded-r-3xl rounded-l-[30px] mt-4">
            <div className="w-full flex lg:flex-row flex-col lg:gap-0 gap-4">
              <div className="lg:w-[35%]">
                <img src={customer} alt="customer" className="w-full h-full object-cover rounded-3xl" />
              </div>
              <div className="lg:w-[65%] flex flex-col items-start justify-center lg:px-[50px] px-3 lg:py-[50px] py-3">
                <img src={logo} alt="logo" />
                <p className="my-8 text-lg text-text4">
                  "Very pleasant to work with Cogntix, they were open to our
                  suggestions, and worked within our deadlines and our budget!"
                </p>
                <h2 className="font-semibold text-xl text-text4 mb-1">
                  Rama Sunatharasarma
                </h2>
                <p className="text-text3 italic">Co-founder of Recipe lab</p>
                <h2 className="text-secondary uppercase">FRANCE</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DemoCallSection />
      <FooterSection />
    </>
  );
};

export default Project2;
