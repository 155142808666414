import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { PiCaretLeftBold } from "react-icons/pi";
import client1 from "../../assests/Project1/client1.png";
import projectDetail1 from "../../assests/Project1/ProjectDetail1.png";
import project1Sample1 from "../../assests/Project1/project1sample1.png";
import project1Sample2 from "../../assests/Project1/project1sample2.png";
import project1Sample3 from "../../assests/Project1/project1sample3.png";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import DemoCallSection from "../LandingPage/DemoCallSection";
import FooterSection from "../FooterSection";
import { Helmet } from "react-helmet";

const ImagePreview = ({ isOpen, image, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div className="relative max-w-[90vw] max-h-[90vh]">
        <button
          onClick={onClose}
          className="absolute -top-8 -right-5 text-white hover:text-gray-300"
        >
          <div className="flex justify-center items-center bg-gray-200 rounded-full p-1">
            <IoMdClose className="w-5 h-5 text-gray-600" />
          </div>
        </button>
        <img
          src={image}
          alt="Preview"
          className="max-w-full max-h-[85vh] object-contain"
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

const Project1 = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const tags = [
    "Ideation",
    "UI/UX Design",
    "Full Stack Development",
    "Support",
  ];

  const projectInfo = [
    {
      label: "Industry",
      value: "E-commerce",
    },
    {
      label: "Location",
      value: "Sri Lanka",
    },
    {
      label: "Client",
      value: "Naresh Shanmuraj",
    },
    {
      label: "Time",
      value: "6 months",
    },
  ];

  const objectives = [
    "Offer small businesses with a simple, no-code solution to set up a professional online store effortlessly.",
    "Allow vendors to receive and manage orders through email or WhatsApp, making it easy to respond quickly to customers.",
    "Offer a user-friendly platform that ensures customers can browse products, customize orders, and complete transactions with ease.",
    "Help vendors to manage discounts and special offers to attract new customers and keep loyal ones.",
    "Provide vendors tools to update products, track orders, and analyze performance for efficient growth.",
  ];

  const sampleImages = [
    { src: project1Sample1, alt: "project1Sample1" },
    { src: project1Sample2, alt: "project1Sample2" },
    { src: project1Sample3, alt: "project1Sample3" },
  ];

  return (
    <>
      <Helmet>
        <title>
          FEASTO | Easy Web Store Builder for Small Businesses in Sri Lanka
        </title>
        <meta
          name="description"
          content="This case study highlights how Feasto partnered with us at Cogntix to build a user-friendly eCommerce platform, helping small businesses easily manage their online stores and improve customer experience. "
        />
        <meta
          name="keywords"
          content="Feasto, Web store builder for small businesses, eCommerce platform Sri Lanka, easy online store builder, Ecommerce Web store, Online store, Cogntix case study"
        />
      </Helmet>
      <Navbar />
      <div className="bg-white lg:mt-[150px] mt-[100px] lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <div
          onClick={() => handleNavigate("/ourWorks")}
          className="flex felx-row gap-3 items-center group cursor-pointer"
        >
          <PiCaretLeftBold className="text-xl text-gray-500 group-hover:text-secondary" />
          <p className="text-lg text-gray-500 group-hover:text-secondary">
            Back to Our works
          </p>
        </div>
        <div className="justify-start mt-4">
          <img src={client1} alt="client1" />
          <div className="mt-10 flex flex-wrap gap-3 md:w-[40%] w-full">
            {tags.map((tag, index) => (
              <div
                key={index}
                className="bg-gray-200 inline-flex justify-center items-center rounded-3xl py-1 px-4 font-medium text-primary text-sm whitespace-nowrap"
              >
                {tag}
              </div>
            ))}
          </div>
          <h1 className="ourWorkHeading mb-6 mt-4 lg:w-[65%]">
            FEASTO-Web Store Builder for Small Businesses
          </h1>
          <p className="lg:w-[65%] text-text2 text-lg">
            FEASTO, a Sri Lankan e-commerce web store builder, has approached us
            to build an easy platform for small businesses to create and run
            their online stores without any technical skills.
          </p>
          <div className="mt-12 lg:w-[70%] grid md:grid-cols-4 grid-cols-2 lg:gap-12 gap-8">
            {projectInfo.map((info, index) => (
              <div key={index}>
                <p className="text-sm text-gray-700 uppercase">{info.label}</p>
                <p className="text-xl text-text4 font-semibold font-inter">
                  {info.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full lg:mt-14 mt-10 lg:px-[90px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <img
          src={projectDetail1}
          alt="projectDetail3"
          className="w-full h-full"
        />
      </div>
      <div className="lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2 my-20">
        <div className="flex flex-col gap-20">
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Background</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  FEASTO is a Sri Lanka-based eCommerce platform designed to
                  help small and micro-businesses create and manage online
                  stores with ease. Many small business owners face challenges
                  with limited resources, technical knowledge, and managing an
                  online presence. FEASTO solves these issues by offering a
                  no-code solution, enabling vendors from restaurants, retail
                  and grocery stores to set up their online stores without any
                  technical skills. The platform features customizable templates
                  for quick business launches, a centralized order management
                  system, and real-time order notifications.
                </span>
                <br />
                <span>
                  Their mission is to empower small business owners by helping
                  them overcome digital barriers, simplify store management,
                  enhance customer satisfaction, and drive business growth in
                  the online marketplace.
                </span>
              </p>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Objective</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  FEASTO removes the barriers that many small vendors face, such
                  as limited technical knowledge and resources. This allows them
                  to focus on what matters most, growing their business and
                  serving their customers.
                </span>
                <br />
                <span>
                  The primary objective of FEASTO is to help small business
                  owners succeed in the digital world without the need for
                  technical knowledge. By offering simple tools for store
                  management and order processing FEASTO ensures that vendors
                  can create a seamless shopping experience and optimize their
                  operations with ease.
                </span>
              </p>
              <div className="flex flex-col gap-6 mt-12">
                {objectives.map((objective, index) => (
                  <div key={index} className="flex flex-row  gap-3">
                    <div>
                      <FaCheck className="size-6 text-secondary mt-1" />
                    </div>
                    <div>
                      <p className="text-lg text-text2">{objective}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Solution</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  We worked closely with FEASTO to design and build a
                  user-friendly platform that simplifies the process from start
                  to finish. Our team developed a no-code solution that enables
                  vendors to effortlessly set up digital catalogs, manage orders
                  through WhatsApp and Email, and track sales performance all
                  from a single dashboard. With FEASTO's user-friendly design,
                  even those with minimal technical knowledge can successfully
                  run their online stores.
                </span>
                <br />
                <span>
                  With our solution, FEASTO has successfully empowered small
                  business owners to launch their online stores effortlessly and
                  has helped hundreds of small businesses grow and reach new
                  customers online since its launch.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col mt-20 w-full gap-5 lg:max-h-[500px]">
        {sampleImages.map((image, index) => (
          <img
            key={index}
            src={image.src}
            alt={image.alt}
            className="w-full cursor-pointer transition-transform hover:scale-[1.02]"
            onClick={() => setSelectedImage(image.src)}
          />
        ))}
      </div>

      <ImagePreview
        isOpen={!!selectedImage}
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
      />

      <DemoCallSection />
      <FooterSection />
    </>
  );
};

export default Project1;
