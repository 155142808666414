import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import blog1 from "../../assests/Blog1/Blog1.png";
import blog2 from "../../assests/Blog2/Blog2.png";
import blog3 from "../../assests/Blog3/Blog3.png";
import blog4 from "../../assests/Blog4/Blog4.png";
import { FaArrowRightLong } from "react-icons/fa6";

const ALL_BLOGS = [
  {
    id: 1,
    image: blog1,
    date: "Jun 27, 2024",
    author: "",
    category: "Engineering",
    title: "How to Hire a Great Team to Build your Digital Product",
    excerpt:
      "Feasto streamlines online retail operations, providing stunning, customizable templates that attract customers...",
    path: "/blog/How-to-Hire-a-Great-Team-to-Build-your-Digital-Product",
  },
  {
    id: 2,
    image: blog2,
    date: "Aug 30, 2024",
    author: "",
    category: "Design",
    title: "7 Important but underrated skills for beginner software developers",
    excerpt:
      "When you think of exploratory testing, do you picture a tester clicking through the app or analyzing requirements? I bet most would...",
    path: "/blog/Exploratory-Testing-in-Requirement-Analysis",
  },
  {
    id: 3,
    image: blog3,
    date: "Sep 13, 2024",
    author: "",
    category: "ML & AI",
    title: "Getting Started with Machine Learning: A Beginner's Guide",
    excerpt:
      "Design thinking isn't just a buzzword. It's a powerful approach that empowers you to tackle complex challenges by putting...",
    path: "/blog/Why-Does-Your-Project-Need-Design-Thinking-and-How-to-Implement-It",
  },
  {
    id: 4,
    image: blog4,
    date: "Nov 15, 2024",
    author: "",
    // category: "Quality Assurance",
    title: "Best Practices for Test Automation in 2024",
    excerpt:
      "Imagine a world where technology works like a smart assistant, making our jobs easier and faste. That’s the power of...",
    path: "/blog/How-LLMs-Can-Change-the-Way-Businesses-Work?",
  },
];

const useRelatedBlogs = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  // Filter out current blog and get first 3 other blogs
  const relatedBlogs = ALL_BLOGS.filter(
    (blog) => blog.path !== currentPath
  ).slice(0, 3);

  return relatedBlogs;
};

const BlogsPreview = () => {
  const navigate = useNavigate();
  const relatedBlogs = useRelatedBlogs();

  const handleNavigate = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };

  const handleViewMoreClick = () => {
    window.scrollTo(0, 0);
    navigate("/blogs");
  };

  return (
    <div className="flex flex-col lg:px-[100px] lg:py-[40px] larger:px-[200px] mx-auto max-w-[2000px] py-16 px-3">
      <h1 className="heading1 lg:mb-6 mb-4 text-center">Our Latest Blogs</h1>
      <p className="text-center text-text2 text-lg">
        Dive into our blogs and gain insights
      </p>

      <div className="mt-16 grid w-full lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
        {relatedBlogs.map((blog) => (
          <div
            onClick={() => handleNavigate(blog.path)}
            key={blog.id}
            className="group cursor-pointer relative bg-white rounded-xl overflow-hidden transition-all duration-300"
          >
            <div className="overflow-hidden relative">
              <img
                src={blog.image}
                alt={blog.title}
                className="w-full transition-transform duration-300 group-hover:scale-105"
              />
              {/* Category pill - commented for now but kept for future use */}
              {/* <div className="absolute top-4 left-4">
                <span className="text-sm bg-primary text-white px-3 py-1 rounded-full">
                  {blog.category}
                </span>
              </div> */}
            </div>

            <div className="flex flex-col items-start p-2 pt-4">
              <h3 className="text-sm text-gray-500 font-medium transition-colors duration-300 group-hover:text-primary">
                {blog.date} {blog.author && `By ${blog.author}`}
              </h3>
              <h2 className="text-xl text-primary font-semibold mb-4 max-w-[390px]">
                {blog.title}
              </h2>
              <p className="text-gray-400 mb-4 max-w-[370px] transition-colors duration-300 group-hover:text-gray-600">
                {blog.excerpt}
              </p>
              <button className="mb-2 flex flex-row gap-2 items-center justify-center text-lg text-secondary font-medium group/button">
                <p className="transition-transform duration-300 group-hover/button:translate-x-1">
                  Read more
                </p>
                <FaArrowRightLong className="mt-1 opacity-0 -translate-x-2 transition-all duration-300 group-hover:opacity-100 group-hover:translate-x-0" />
              </button>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center mt-16">
        <button
          onClick={handleViewMoreClick}
          className="group px-6 py-2 flex flex-row gap-3 items-center justify-center bg-white rounded-lg border-primary border-[1px] min-w-[250px]"
        >
          <p className="font-medium text-primary text-lg">View More Blogs</p>
          <FaArrowRightLong className="size-5 group-hover:inline hidden transition-all" />
        </button>
      </div>
    </div>
  );
};

export default BlogsPreview;
