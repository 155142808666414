import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPageMainScreen from "./pages/LandingPageMainScreen";
import OurWorksMainPage from "./pages/OurWorksMainPage";
import Project1 from "./components/OurWorks/Project1";
import Project2 from "./components/OurWorks/Project2.jsx";
import Project3 from "./components/OurWorks/Project3";
import Project4 from "./components/OurWorks/Project4.jsx";
import Project5 from "./components/OurWorks/Project5.jsx";
import Project6 from "./components/OurWorks/Project6.jsx";
import Project7 from "./components/OurWorks/Project7.jsx";
import Subscriptions from "./pages/Subscriptions";
import ProductEngineering from "./pages/Services/ProductEngineeringMainPage";
import ProductDesign from "./pages/Services/ProductDesignMainPage.jsx";
import ScrollToTop from "./components/ScrollToTop.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import TermsandConditions from "./pages/TermsandConditions.jsx";
import Blogs from "./pages/Blogs.jsx";
import Blog1 from "./components/Blogs/Blog1.jsx";
import Blog2 from "./components/Blogs/Blog2.jsx";
import Blog3 from "./components/Blogs/Blog3.jsx";
import Blog4 from "./components/Blogs/Blog4.jsx";
import Careers from "./pages/Careers.jsx";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <>
          <Routes>
            <Route path="/" element={<LandingPageMainScreen />} />
            <Route path="/subscriptions" element={<Subscriptions />} />
            {/* Blogs */}
            <Route path="/blogs" element={<Blogs />} />
            <Route
              path="/blog/How-to-Hire-a-Great-Team-to-Build-your-Digital-Product"
              element={<Blog1 />}
            />
            <Route
              path="/blog/Exploratory-Testing-in-Requirement-Analysis"
              element={<Blog2 />}
            />
            <Route
              path="/blog/Why-Does-Your-Project-Need-Design-Thinking-and-How-to-Implement-It"
              element={<Blog3 />}
            />
            <Route
              path="/blog/How-LLMs-Can-Change-the-Way-Businesses-Work?"
              element={<Blog4 />}
            />
            <Route
              path="/services/productengineering"
              element={<ProductEngineering />}
            />
            <Route path="/services/productdesign" element={<ProductDesign />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route
              path="/termsandconditions"
              element={<TermsandConditions />}
            />
            <Route path="/careers" element={<Careers />} />
            {/* Projects */}
            <Route path="/ourWorks" element={<OurWorksMainPage />} />
            <Route path="/ourworks/Feasto" element={<Project1 />} />
            <Route
              path="/ourworks/AI-Powered-Query-Platform"
              element={<Project3 />}
            />
            <Route path="/ourworks/Recipelab" element={<Project2 />} />
            <Route path="/ourworks/Milta" element={<Project4 />} />
            <Route
              path="/ourworks/Bellize-Accounts-Management-Solution-for-Auditing-Firm"
              element={<Project5 />}
            />
            <Route
              path="/ourworks/Parcel-Delivery-System"
              element={<Project6 />}
            />
            <Route
              path="/ourworks/Customers-Bill-Management"
              element={<Project7 />}
            />
          </Routes>
        </>
      </BrowserRouter>
    </>
  );
}

export default App;
