import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { PiCaretLeftBold } from "react-icons/pi";
import projectDetail3 from "../../assests/Project3/ProjectDetail3.png";
import project3Sample1 from "../../assests/Project3/project3sample1.png";
import project3Sample2 from "../../assests/Project3/project3sample2.png";
import project3Sample3 from "../../assests/Project3/project3sample3.png";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import DemoCallSection from "../LandingPage/DemoCallSection";
import FooterSection from "../FooterSection";
import { Helmet } from "react-helmet";

const ImagePreview = ({ isOpen, image, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div className="relative max-w-[90vw] max-h-[90vh]">
        <button
          onClick={onClose}
          className="absolute -top-8 -right-5 text-white hover:text-gray-300"
        >
          <div className="flex justify-center items-center bg-gray-200 rounded-full p-1">
            <IoMdClose className="w-5 h-5 text-gray-600" />
          </div>
        </button>
        <img
          src={image}
          alt="Preview"
          className="max-w-full max-h-[85vh] object-contain"
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

const Project3 = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const tags = ["Ideation", "R&D", "UI/UX Design"];

  const projectInfo = [
    { label: "Industry", value: "Construction" },
    { label: "Location", value: "Canada" },
    { label: "Client", value: "Kevin Bobroske" },
    { label: "Time", value: "1 month" },
  ];

  const objectivesList = [
    "Speed up the process of creating initial project quotes based on past data.",
    "Allow project managers to keep track of different projects and departments in one platform.",
    "Make it easy to get updates from each department head without extra effort.",
    "Save time on repetitive tasks so teams can focus more on actual project work.",
  ];

  const projectSamples = [
    { image: project3Sample1, alt: "project3Sample1" },
    { image: project3Sample2, alt: "project3Sample2" },
    { image: project3Sample3, alt: "project3Sample3" },
  ];

  return (
    <>
      <Helmet>
        <title>AI-Powered Query Platform for Construction Companies</title>
        <meta
          name="description"
          content="Find out how we helped an AI-powered query platform for construction companies streamline data queries and improve productivity through our advanced technology solutions. "
        />
        <meta
          name="keywords"
          content="construction industry, AI-powered query platform, Construction data management, AI in construction industry, Construction company software case study, Cogntix case studies"
        />
      </Helmet>
      <Navbar />
      <div className="bg-white lg:mt-[150px] mt-[100px] lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <div
          onClick={() => handleNavigate("/ourWorks")}
          className="flex felx-row gap-3 items-center group cursor-pointer"
        >
          <PiCaretLeftBold className="text-xl text-gray-500 group-hover:text-secondary" />
          <p className="text-lg text-gray-500 group-hover:text-secondary">
            Back to Our works
          </p>
        </div>
        <div className="justify-start mt-4">
          <div className="mt-10 flex flex-wrap gap-3 md:w-[40%] w-full">
            {tags.map((tag, index) => (
              <div
                key={index}
                className="bg-gray-200 inline-flex justify-center items-center rounded-3xl py-1 px-4 text-primary font-medium text-sm whitespace-nowrap"
              >
                {tag}
              </div>
            ))}
          </div>
          <h1 className="ourWorkHeading mb-6 mt-4 lg:w-[75%]">
            AI-Powered Query Platform for Construction Companies
          </h1>
          <p className="lg:w-[65%] text-text2 text-lg">
            Platform for construction firms to streamline project plans with
            instant quotes, automated updates, and multi-project management all
            within a unified portal.
          </p>
          <div className="mt-12 lg:w-[70%] grid md:grid-cols-4 grid-cols-2 lg:gap-12 gap-8">
            {projectInfo.map((info, index) => (
              <div key={index}>
                <p className="text-sm text-gray-700 uppercase">{info.label}</p>
                <p className="text-xl text-text4 font-semibold font-inter">
                  {info.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full lg:mt-14 mt-10 lg:px-[90px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <img
          src={projectDetail3}
          alt="projectDetail3"
          className="w-full h-full"
        />
      </div>

      <div className="lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2 my-20">
        <div className="flex flex-col gap-20">
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Background</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  In the construction industry, project planning and budgeting
                  can be complex and time-consuming. Engineers and managers
                  often spend hours creating project estimates and tracking
                  progress across various departments, such as engineering,
                  plumbing, and electrical. Many of these tasks are done
                  manually, which can lead to delays, missed information, and
                  extra work.
                </span>
                <br />
                <span>
                  The construction company wanted a solution that would make
                  their work faster and more accurate. They needed a way to
                  automatically generate estimates, collect daily progress
                  updates from each department, and manage all their projects in
                  one place.
                </span>
              </p>
            </div>
          </div>

          {/* Objective Section */}
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Objective</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  The main goals of the AI-Powered Query Platform were to:
                </span>
                <br />
              </p>
              <div className="flex flex-col gap-6 mt-10">
                {objectivesList.map((objective, index) => (
                  <div key={index} className="flex flex-row  gap-3">
                    <div>
                      <FaCheck className="size-6 text-secondary mt-1" />
                    </div>
                    <div>
                      <p className="text-lg text-text2">{objective}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Solution Section - Not using map as requested */}
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Solution</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full text-lg text-text2">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  We worked closely with the client to understand their
                  challenges in managing multiple construction projects and
                  teams. The main issue was the time-consuming task of creating
                  initial quotes and manually collecting daily updates from
                  various departments. To address this, we developed an
                  AI-powered platform that automates both processes. We worked
                  with the client to integrate past project data into the
                  system, allowing the AI to instantly generate reliable
                  quotations. Additionally, we set up automated daily update
                  calls, which were transcribed into text, saving time and
                  ensuring accuracy. The platform’s unified dashboard enabled
                  the client to manage all projects and teams in one place,
                  improving communication and control.
                </span>
                <br />
                <span>
                  Through this collaboration, we helped streamline their
                  operations, reduce administrative work, and improve
                  efficiency. As a result, the client was able to complete
                  projects faster and enhance team coordination.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col mt-20 w-full gap-5 lg:max-h-[500px]">
        {projectSamples.map((image, index) => (
          <img
            key={index}
            src={image.image}
            alt={image.alt}
            className="w-full cursor-pointer transition-transform hover:scale-[1.02]"
            onClick={() => setSelectedImage(image.image)}
          />
        ))}
      </div>

      <ImagePreview
        isOpen={!!selectedImage}
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
      />

      <DemoCallSection />
      <FooterSection />
    </>
  );
};

export default Project3;
