import React, { useState } from "react";
import { FaCheck } from "react-icons/fa6";
import { HiMiniXMark } from "react-icons/hi2";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FaArrowRightLong } from "react-icons/fa6";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { uploadFormData } from "../../api/clientService";
import { MdErrorOutline } from "react-icons/md";

const pricingData = [
  {
    plan: "essential",
    price: 2399,
    description: "1 Expert Talent",
    features: [
      "1 request at a time",
      "Average 1-3 day delivery",
      "1 Full stack engineer",
      "3 days free trial",
      "Unlimited revisions",
      "No Long term contracts (Pause or Cancel anytime)",
      "Dedicated Notion Board",
      "Communication on Slack or your preferred channel",
    ],
  },
  {
    plan: "Accelerate",
    price: 6599,
    description: "3 Expert Talents",
    features: [
      "Maximum 3 requests at a time",
      "Average 1-3 day delivery",
      "3 Full stack engineers",
      "3 days free trial",
      "Unlimited revisions",
      "No Long term contracts (Pause or Cancel anytime)",
      "Dedicated Notion Board",
      "Communication on Slack or your preferred channel",
    ],
  },
  {
    plan: "Growth",
    price: "Custom Price",
    description: "Customizable",
    features: [
      "Unlimited requests at a time",
      "Average 1-3 day delivery",
      "Full stack engineers (As Needed)",
      "3 days free trial",
      "Unlimited revisions",
      "No Long term contracts (Pause or Cancel anytime)",
      "Dedicated Notion Board",
      "Communication on Slack or your preferred channel",
    ],
  },
];

const PricingFormPopup = ({ isOpen, onClose, plan }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    emailError: "",
    phone: "",
    message: "",
    timeline: "",
  });

  if (!isOpen) return null;

  const getPlanTitle = () => {
    switch (plan?.toLowerCase()) {
      case "essential":
        return "Essential - 1 Expert Talents   $2399.00";
      case "accelerate":
        return "Accelerate - 3 Expert Talents   $6599.00";
      case "growth":
        return "Growth - Talent on Demand";
      default:
        return "";
    }
  };

  const getPlanTemplate = () => {
    switch (plan?.toLowerCase()) {
      case "essential":
        return "essentialDevelopmentSubscriptionInquiry";
      case "accelerate":
        return "accelerateGrowthDevelopmentSubscriptionInquiry";
      case "growth":
        return "growthDevelopmentSubscriptionInquiry";
      default:
        return "";
    }
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const timeline = [
    "Less than 3 months",
    "About 6 months",
    "More than 6 months",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const payloadData = {
      template: getPlanTemplate(),
      clientName: formData.name,
      clientEmail: formData.email,
      clientMobileNo: formData.phone,
      projectIdea: formData.message,
      timeline: formData.timeline,
    };

    try {
      // Simulate API call
      const response = await uploadFormData(payloadData);
      setIsSubmitting(false);
      setIsSubmitted(true);

      // Show success state for 2 seconds before closing
      setTimeout(() => {
        // Reset form and states
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
          timeline: "",
          percentage: "",
        });
        setIsSubmitted(false);
        onClose();
      }, 2000);
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error submitting form:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Email validation
    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailPattern.test(value);

      // Add error state to track validation
      setFormData((prev) => ({
        ...prev,
        email: value,
        emailError:
          !isValidEmail && value !== ""
            ? "Invalid Email Address. Please enter a valid Email Address !"
            : "",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      phone: value,
    }));
  };

  return (
    <div
      onClick={handleOverlayClick}
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
    >
      <div className="relative rounded-3xl shadow-xl px-10 py-6 max-w-[700px] max-h-[600px] bg-white overflow-y-auto custom-scroll">
        <div className="sticky top-0 left-0 w-full flex justify-end -mt-2 lg:-mr-4 ml-5 pb-4">
          <div
            onClick={onClose}
            className="group p-1 hover:bg-gray-100 bg-gray-50 rounded-full"
          >
            <HiMiniXMark className="text-xl text-gray-600  group-hover:text-primary" />
          </div>
        </div>
        <div className="flex-col flex- items-start mb-10">
          <h1 className="text-primary lg:text-[36px] text-center text-3xl font-extrabold  mb-4">
            Start Today with the Perfect Plan
          </h1>
          <div className="px-4 py-1 mx-auto rounded-full w-fit bg-secondary flex items-center justify-center text-primary font-medium lg:text-lg text-xs text-center">
            {getPlanTitle()}
          </div>
        </div>
        <div className="flex lg:flex-row flex-col gap-10">
          <div className=" flex flex-col">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="lg:min-w-[530px] w-full py-2 px-3 border-[1px] border-gray-400 rounded-md mb-6 focus:outline-none focus:ring-1 focus:ring-gray-300"
                placeholder="Name"
                required
              />

              <div className="mb-6">
                <div className="relative">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`lg:min-w-[530px] w-full py-2 px-3 border-[1px] ${
                      formData.emailError ? "border-red-500" : "border-gray-400"
                    } rounded-md focus:outline-none focus:ring-1 focus:ring-gray-300`}
                    placeholder="Email address"
                    required
                  />
                  {formData.emailError && (
                    <MdErrorOutline className="absolute right-3 top-1/2 -translate-y-1/2 text-red-500 text-xl" />
                  )}
                </div>
                {formData.emailError && (
                  <p className="text-red-500 text-sm mt-1 mb-2 font-medium">
                    {formData.emailError}
                  </p>
                )}
              </div>

              <div className="relative space-y-[0.5px] mb-6">
                <PhoneInput
                  country="lk"
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  enableSearch={true}
                  inputProps={{
                    name: "mobile",
                    required: true,

                    className:
                      "mt-[-0.5px] lg:min-w-[530px] w-full pl-[50px] pr-[12px] py-2 border border-gray-400 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-300 mt-1",
                  }}
                  containerClass="!static"
                  buttonClass="!absolute !top-1/2 !-translate-y-1/2 !left-2 !z-10 !border-none !bg-white !size-8 "
                  dropdownClass="!absolute !top-full !left-0 !mt-1"
                />
              </div>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="lg:min-w-[530px] w-full py-2 px-3 border-[1px] border-gray-400 rounded-md mb-4 focus:outline-none focus:ring-1 focus:ring-gray-300"
                rows={4}
                placeholder="Tell us a bit about your idea"
              />
              <div className="space-y-3 mb-10">
                <label
                  htmlFor="timeline"
                  className="block font-medium text-primary"
                >
                  How long are you planning to subscribe for?
                </label>
                <div className="flex flex-wrap gap-3">
                  {timeline.map((option) => (
                    <label key={option} className="relative">
                      <input
                        required
                        type="radio"
                        name="timeline"
                        value={option}
                        checked={formData.timeline === option}
                        onChange={handleChange}
                        className="peer absolute opacity-0"
                      />
                      <div className="bg-gray-200 px-4 py-1 flex items-center justify-center rounded-full cursor-pointer peer-checked:bg-primary peer-checked:text-white transition-colors">
                        <span className="text-sm font-medium">{option}</span>
                      </div>
                    </label>
                  ))}
                </div>
              </div>

              <div className="flex lg:flex-row flex-col-reverse lg:justify-between gap-4 lg:items-center mb-6">
                <a
                  href="https://cal.com/naresh-shanmugaraj/discussion"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 lg:text-left text-center hover:text-secondary"
                >
                  Prefer to schedule a call?
                </a>
                <button
                  type="submit"
                  disabled={isSubmitting || isSubmitted}
                  className={`py-3 px-6 rounded-md bg-primary 
                    text-white font-semibold min-w-[120px] flex items-center justify-center gap-2 ${
                      isSubmitting || isSubmitted ? "cursor-not-allowed" : ""
                    }`}
                >
                  {isSubmitting ? (
                    <>
                      <div className="w-5 h-5 border-2 border-white border-t-secondary rounded-full animate-spin" />
                    </>
                  ) : isSubmitted ? (
                    <>
                      <BsFillCheckCircleFill className="text-green-600 size-[18px]" />
                      <span>Submitted!</span>
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const PricingCard = ({ plan, price, description, features }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  return (
    <>
      <div className="flex flex-col w-full shadow-md rounded-lg bg-gray-50 group transition-all duration-300 hover:-translate-y-2 hover:shadow-xl">
        <div className="px-10 pt-10 flex flex-col items-start rounded-t-lg bg-primary min-h-[220px] transition-colors duration-300 group-hover:bg-primary/90">
          <p className="bg-indigo-100 px-4 py-1 rounded-2xl text-indigo-600 uppercase text-sm font-medium tracking-[0.35px] mb-4 transition-colors duration-300 group-hover:bg-indigo-200">
            {plan}
          </p>
          <p className="text-white lg:text-6xl text-5xl font-bold lg:mb-5 mb-2">
            {typeof price === "number" ? (
              <>
                ${price}
                <span className="text-gray-400 text-[24px] font-medium">
                  /mo
                </span>
              </>
            ) : (
              <span className="lg:text-[50px] text-3xl">{price}</span>
            )}
          </p>
          <p className="text-lg text-white">{description}</p>
        </div>
        <div className="px-10 pt-10 pb-6 flex flex-col h-full rounded-b-lg bg-gray-50 transition-colors duration-300 group-hover:bg-white">
          <div className="flex flex-col space-y-4 flex-grow mb-6">
            {features.map((feature, index) => (
              <div key={index} className="flex flex-row space-x-3">
                <FaCheck className="size-6 text-green-600 flex-shrink-0 transition-transform duration-300 group-hover:scale-110" />
                <p className="text-gray-500 flex-1">{feature}</p>
              </div>
            ))}
          </div>
          <button
            onClick={() => setIsPopupOpen(true)}
            className=" w-full rounded-lg bg-primary flex flex-row gap-3 items-center justify-center py-4 px-6 text-white text-lg font-medium transition-all duration-300 group-hover:scale-[1.02] active:scale-[0.98]"
          >
            Get started now
            <FaArrowRightLong className="size-5 group-hover:inline hidden transition-all" />
          </button>
        </div>
      </div>

      <PricingFormPopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        plan={plan}
        price={price}
      />
    </>
  );
};

const DevelopmentPricing = () => {
  return (
    <div className="flex flex-col lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] lg:py-[80px] py-16 px-3">
      <div className="flex flex-col justify-center">
        <h2 className="semiHeading text-center">PRODUCT ENGINEERING</h2>
        <h2 className="heading1 mb-6 text-center">
          Explore the Development Plans
        </h2>
        <p className="text-center text-text2 text-lg mb-16">
          Find the perfect subscription plan to suit your business requirements
          and objectives
        </p>
      </div>
      <div className="mt-10 flex lg:flex-row flex-col gap-6 justify-between">
        {pricingData.map((card, index) => (
          <PricingCard key={index} {...card} />
        ))}
      </div>
    </div>
  );
};

export default DevelopmentPricing;
