import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { PiCaretLeftBold } from "react-icons/pi";
import projectDetail4 from "../../assests/Project4/ProjectDetail4.png";
import project4Sample1 from "../../assests/Project4/project4sample1.png";
import project4Sample2 from "../../assests/Project4/project4sample2.png";
import project4Sample3 from "../../assests/Project4/project4sample3.png";
import customer from "../../assests/Project4/customer2.png";
import logo from "../../assests/Project4/customerlogo2.png";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import DemoCallSection from "../LandingPage/DemoCallSection";
import FooterSection from "../FooterSection";
import { Helmet } from "react-helmet";

const ImagePreview = ({ isOpen, image, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div className="relative max-w-[90vw] max-h-[90vh]">
        <button
          onClick={onClose}
          className="absolute -top-8 -right-5 text-white hover:text-gray-300"
        >
          <div className="flex justify-center items-center bg-gray-200 rounded-full p-1">
            <IoMdClose className="w-5 h-5 text-gray-600" />
          </div>
        </button>
        <img
          src={image}
          alt="Preview"
          className="max-w-full max-h-[85vh] object-contain"
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

const Project4 = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const tags = ["Market Research", "Ideation", "UI/UX Design"];

  const projectInfo = [
    { label: "Industry", value: "Telecommunication" },
    { label: "Location", value: "Belgium" },
    { label: "Client", value: "Gobiraj Karunananthan" },
    { label: "Time", value: "2 month" },
  ];

  const objectivesList = [
    "Create an efficient onboarding system that reduces delays and improves accuracy in data collection.",
    "Make it easier and faster for retailers to join the network.",
    "Give admins a single platform to easily manage retailer and employee data, reducing manual work.",
    "Verify all retailers and employees to keep the network secure.",
  ];

  const projectSamples = [
    { image: project4Sample1, alt: "project4Sample1" },
    { image: project4Sample2, alt: "project4Sample2" },
    { image: project4Sample3, alt: "project4Sample3" },
  ];

  return (
    <>
      <Helmet>
        <title>MILTA RMS | Retail Management Solution for Telecommunication Provider</title>
        <meta
          name="description"
          content="Read how MILTA RMS, developed by Cogntix, helped a telecommunications provider simplify retailer onboarding and management. This case study highlights how our solution improved efficiency, data accuracy, and security. "
        />
        <meta
          name="keywords"
          content="MILTA RMS, Retail Management Solution, Cogntix Case Studies, Telecommunications industry, Retail Management case study"
        />
      </Helmet>
      <Navbar />
      <div className="bg-white lg:mt-[150px] mt-[100px] lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <div
          onClick={() => handleNavigate("/ourWorks")}
          className="flex felx-row gap-3 items-center group cursor-pointer"
        >
          <PiCaretLeftBold className="text-xl text-gray-500 group-hover:text-secondary" />
          <p className="text-lg text-gray-500 group-hover:text-secondary">
            Back to Our works
          </p>
        </div>
        <div className="justify-start mt-4">
          <img src={logo} alt="logo" />
          <div className="mt-10 flex flex-wrap gap-3 md:w-[40%] w-full">
            {tags.map((tag, index) => (
              <div
                key={index}
                className="bg-gray-200 inline-flex justify-center items-center rounded-3xl py-1 px-4 text-primary font-medium text-sm whitespace-nowrap"
              >
                {tag}
              </div>
            ))}
          </div>
          <h1 className="ourWorkHeading mb-6 mt-4 lg:w-[90%]">
            MILTA RMS - Retail Management Solution for Telecommunication
            Provider
          </h1>
          <p className="lg:w-[65%] text-text2 text-lg">
            Platform designed to simplify onboarding and managing retailers for
            a telecommunication provider.
          </p>
          <div className="mt-12 lg:w-[80%] grid md:grid-cols-4 grid-cols-2 lg:gap-12 gap-8">
            {projectInfo.map((info, index) => (
              <div key={index}>
                <p className="text-sm text-gray-700 uppercase">{info.label}</p>
                <p className="text-xl text-text4 font-semibold font-inter">
                  {info.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full lg:mt-14 mt-10 lg:px-[90px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <img
          src={projectDetail4}
          alt="projectDetail4"
          className="w-full h-full"
        />
      </div>

      <div className="lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2 my-20">
        <div className="flex flex-col gap-20">
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Background</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  MILTA RMS was developed to help a telecommunications provider
                  streamline the complex process of onboarding and managing
                  retailers. Previously, the onboarding process required
                  extensive paperwork, manual checks, and multiple steps, making
                  it time-consuming and inefficient. Admins struggled with
                  tracking retailer activities, verifying information, and
                  ensuring compliance with company policies. Recognizing these
                  challenges, the client approached us to build a digital
                  solution that centralizes and streamlines retailer management.
                </span>
                <br />
              </p>
            </div>
          </div>

          {/* Objective Section */}
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Objective</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>The main objectives of MILTA RMS were clear:</span>
                <br />
              </p>
              <div className="flex flex-col gap-6 mt-10">
                {objectivesList.map((objective, index) => (
                  <div key={index} className="flex flex-row  gap-3">
                    <div>
                      <FaCheck className="size-6 text-secondary mt-1" />
                    </div>
                    <div>
                      <p className="text-lg text-text2">{objective}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Solution Section - Not using map as requested */}
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Solution</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full text-lg text-text2">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  We worked closely with the telecommunications provider to
                  understand their challenges in managing and onboarding
                  retailers. Together, we developed MILTA RMS, a streamlined
                  digital platform tailored to meet their specific needs. This
                  solution provided a single, unified space where admins could
                  efficiently handle retailer information, approvals, and
                  ongoing management. With MILTA RMS, the process of onboarding
                  retailers became much faster and more organized. Employees can
                  now submit retailer details through an easy-to-use digital
                  form, which includes options for uploading important documents
                  like ID and address proofs. We also implemented OTP
                  verification to ensure that all contact details were accurate
                  and verified.
                </span>
                <br />
                <span>
                  MILTA RMS made a huge impact by centralizing data which
                  allowed admins to view, search, and manage all retailer and
                  employee information from one place.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col mt-20 w-full gap-5 lg:max-h-[500px]">
        {projectSamples.map((image, index) => (
          <img
            key={index}
            src={image.image}
            alt={image.alt}
            className="w-full cursor-pointer transition-transform hover:scale-[1.02]"
            onClick={() => setSelectedImage(image.image)}
          />
        ))}
      </div>

      <ImagePreview
        isOpen={!!selectedImage}
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
      />

      <div className="bg-gray-100 mt-24 p-5">
        <div className="w-full lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
          <div className="w-[90%] mx-auto rounded-r-3xl rounded-l-[30px] mt-4">
            <div className="w-full flex lg:flex-row flex-col lg:gap-0 gap-4">
              <div className="lg:w-[35%]">
                <img src={customer} alt="customer" className="w-full h-full object-cover rounded-3xl" />
              </div>
              <div className="lg:w-[65%] flex flex-col items-start justify-center lg:px-[50px] px-3 lg:py-[50px] py-3">
                <img src={logo} alt="logo" />
                <p className="my-8 text-lg text-text4">
                  "Very pleasant to work with Cogntix, they were open to our
                  suggestions, and worked within our deadlines and our budget!"
                </p>
                <h2 className="font-semibold text-xl text-text4 mb-1">
                  Gobiraj Karunananthan
                </h2>
                <p className="text-text3 italic">CEO of MILTA - RMS</p>
                <h2 className="text-secondary uppercase">BELGIUM</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DemoCallSection />
      <FooterSection />
    </>
  );
};

export default Project4;
