import React from "react";
import groupImage from "../../assests/EngineeringGroup.png";

const HowWeWorkSection = () => {
  const DashedLine = () => (
    <div
      className="absolute left-[35px] top-[48px] md:bottom-[135px] bottom-[220px] w-[3px]"
      style={{
        backgroundImage: "linear-gradient(#FF9130 33%, transparent 33%)",
        backgroundSize: "3px 13px",
        backgroundPosition: "right",
        backgroundRepeat: "repeat-y",
      }}
    />
  );

  return (
    <>
      <div className="flex lg:flex-row flex-col lg:py-[100px] lg:px-[80px] larger:px-[200px] mx-auto max-w-[2000px] py-16 px-3 lg:gap-10 gap-14">
        <div className="lg:w-[50%] w-full">
          <img
            src={groupImage}
            alt="groupImage"
            className="w-full lg:max-h-[1100px]"
          />
        </div>
        <div className="flex flex-col items-start justify-center lg:w-[50%] w-full">
          <h2 className="semiHeading text-left">OUR PROCESS</h2>
          <h2 className="heading1 lg:w-[80%] md:mb-12 mb-6">
            This is How We Work
          </h2>
          <div>
            <div className="p-4 flex flex-col gap-4 relative">
              <DashedLine />

              {[
                {
                  number: 1,
                  title: "Discovery & Research",
                  description:
                    "We begin by understanding your business goals, target audience, and market landscape through research and collaboration. This helps us define the right design strategy to meet your needs.",
                },
                {
                  number: 2,
                  title: "Ideation & Conceptualization",
                  description:
                    "Based on the insights gathered, we brainstorm and develop initial design concepts, focusing on usability and visual appeal. This phase involves creating wireframes and sketches to visualize ideas.",
                },
                {
                  number: 3,
                  title: "Design & Prototyping",
                  description:
                    "Our design team transforms concepts into high-fidelity mockups and interactive prototypes, ensuring a user-centered design. We refine every detail to create a seamless and engaging user experience.",
                },
                {
                  number: 4,
                  title: "User Testing & Validation",
                  description:
                    "We test prototypes with real users, gathering feedback to refine and optimize the design. This iterative process ensures that the final product meets user expectations and business goals.",
                },
                {
                  number: 5,
                  title: "Delivery & Handoff",
                  description:
                    "Once the design is validated, we prepare all assets and documentation for development handoff. We provide full support during implementation to ensure the design is executed accurately and effectively.",
                },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex md:flex-row space-y-4 md:space-y-0 md:space-x-4"
                >
                  <div className="flex-shrink-0 -mb-6 mt-4 z-20">
                    <div className="h-10 w-10 bg-primary rounded-lg border-[3px] border-secondary flex items-center justify-center text-white font-bold">
                      {item.number}
                    </div>
                  </div>
                  <div className="flex-1">
                    <div className="px-4 md:py-3">
                      <h4 className="font-bold text-xl text-gray-900">
                        {item.title}
                      </h4>
                      <p className="mt-2 text-gray-600 text-lg  ">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowWeWorkSection;
