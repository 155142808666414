import React from "react";
import { MdDesignServices } from "react-icons/md";
import { LuCode2 } from "react-icons/lu";
import { BsFillCheckCircleFill } from "react-icons/bs";

const Divider = () => (
  <div className="flex items-center justify-center lg:ml-8">
    <div className="hidden lg:block h-full w-px bg-[#ffffff1f]" />
    <div className="lg:hidden w-full h-px bg-[#ffffff1f] lg:mb-0 mb-8" />
  </div>
);

const Services = () => {
  return (
    <>
      <div className="bg-primary">
        <div className="w-full bg-primary flex flex-col lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2 py-12 ">
          <div className="flex flex-col justify-center">
            <h2 className="semiHeading text-center">SERVICES</h2>
            <h2 className="lg:text-6xl text-[32px] text-white font-bold mb-8 text-center">
              Your Partner in Delivering Tailored Technology Solutions
            </h2>
            <p className="text-center text-white text-lg mb-16">
              We provide tailored software development & support services to
              help your business succeed.
            </p>
          </div>

          <div className="relative bg-primary border-[0.5px] border-[#ffffff1f] shadow-md w-full p-6 flex lg:flex-row flex-col rounded-2xl mb-8 group overflow-hidden">
            {/* Inner glow gradient background */}
            <div className="absolute inset-0 transition-opacity duration-500 opacity-0 group-hover:opacity-100 bg-gradient-to-br from-white/[0.03] via-white/[0.08] to-transparent blur-2xl" />

            <div className="relative z-10 lg:w-[50%] flex flex-col items-start justify-center">
              <MdDesignServices className="text-secondary size-10 mb-6" />
              <h3 className="text-4xl text-white font-bold mb-6">
                Product Design
              </h3>
              <p className="text-white text-lg lg:mb-0 mb-8">
                We transform ideas into engaging digital experiences with
                user-centered design. Interfaces are visually appealing,
                functional, and built for real engagement. With a focus on
                usability, we help your product connect seamlessly with
                users.product connect seamlessly with users.
              </p>
            </div>

            <Divider />

            <div className="relative z-10 lg:w-[50%] lg:pl-9 flex flex-col gap-6 justify-center items-start">
              {[
                "UI/UX Audit",
                "User Research & Wireframing",
                "User Experience Design",
                "User Interface Design",
                "Digital Prototyping & Testing",
              ].map((text, index) => (
                <div key={index} className="flex flex-row gap-6">
                  <BsFillCheckCircleFill className="text-secondary size-6" />
                  <h3 className="text-white text-lg">{text}</h3>
                </div>
              ))}
            </div>
          </div>

          <div className="relative bg-primary border-[0.5px] border-[#ffffff1f] shadow-md w-full p-6 flex lg:flex-row flex-col rounded-2xl mb-8 group overflow-hidden">
            {/* Inner glow gradient background */}
            <div className="absolute inset-0 transition-opacity duration-500 opacity-0 group-hover:opacity-100 bg-gradient-to-br from-white/[0.03] via-white/[0.08] to-transparent blur-2xl" />

            <div className="relative z-10 lg:w-[50%] flex flex-col items-start justify-center">
              <LuCode2 className="text-secondary size-10 mb-6" />
              <h3 className="text-4xl text-white font-bold mb-6">
                Product Engineering
              </h3>
              <p className="text-white text-lg lg:mb-0 mb-8">
                We bring your vision to life with scalable, high-performance
                solutions. Using cutting-edge technology, we build resilient,
                reliable products that grow with your business. Every feature is
                optimized for speed, security, and seamless integration.
              </p>
            </div>

            <Divider />

            <div className="relative z-10 lg:w-[50%] lg:pl-9 flex flex-col gap-6 justify-center items-start">
              {[
                "SaaS Application Development",
                "AI Application Development",
                "Custom Web Development",
                "Mobile Application Development",
                "API Integration and Development",
              ].map((text, index) => (
                <div key={index} className="flex flex-row gap-6">
                  <BsFillCheckCircleFill className="text-secondary size-6" />
                  <p className="text-white text-lg">{text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
