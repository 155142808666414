import React from "react";
import Navbar from "../../components/Navbar";
import HeroSection from "../../components/ProductDesign/HeroSection";
import FeaturesEngineering from "../../components/ProductDesign/FeaturesEngineering";
import HowWeWorkSection from "../../components/ProductDesign/HowWeWorkSection";
import ProjectsPreview from "../../components/ProductEngineering/ProjectsPreview";
import Testimonials from "../../components/Subcriptions/Testimonials";
import DemoCallSection from "../../components/LandingPage/DemoCallSection";
import FooterSection from "../../components/FooterSection";
import { Helmet } from "react-helmet";

const ProductDesign = () => {
  const DotPattern = () => (
    <svg
      className="absolute inset-0 w-full h-full"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <pattern
          id="dotPattern"
          x="0"
          y="0"
          width="20"
          height="20"
          patternUnits="userSpaceOnUse"
        >
          <circle cx="1.5" cy="1.5" r="1.5" fill="rgba(255, 255, 255, 0.1)" />
        </pattern>
      </defs>
      <rect width="100%" height="85%" fill="url(#dotPattern)" />
    </svg>
  );

  return (
    <>
      <Helmet>
        <title>Cogntix | Product Design Services</title>
        <meta
          name="description"
          content="With Cogntix’s product design services, we help bring your creative ideas to life through elegant designs and great user experiences."
        />
        <meta
          name="keywords"
          content="Product Design Services, Cogntix Services, UX/UI services, UI/UX Audit, User Research & Wireframing, Digital Prototyping & Testing, UX Strategy & Consulting"
        />
      </Helmet>
      <Navbar />
      <div className="w-full bg-primary ">
        <DotPattern />
        <div className="flex flex-col mt-[100px] lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] py-16 px-2">
          <h2 className="semiHeading text-left">PRODUCT DESIGN</h2>
          <h1 className="lg:text-6xl text-[32px] text-white font-bold mb-6 w-[95%] md:max-w-[900px]">
            Creative Product Design for Every Business
          </h1>
          <p className="max-w-[900px] whiteParagraph mb-10">
            From bold concepts to polished designs, we create UI/UX solutions
            that elevate user experience, meeting all your business and user
            needs.
          </p>
        </div>
      </div>
      <HeroSection />
      <FeaturesEngineering />
      <HowWeWorkSection />
      <ProjectsPreview />
      <Testimonials />
      <DemoCallSection />
      <FooterSection />
    </>
  );
};

export default ProductDesign;
