import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { PiCaretLeftBold } from "react-icons/pi";
import projectDetail5 from "../../assests/Project5/ProjectDetail5.png";
import project5Sample1 from "../../assests/Project5/project5sample1.png";
import project5Sample2 from "../../assests/Project5/project5sample2.png";
import project5Sample3 from "../../assests/Project5/project5sample3.png";
import customer from "../../assests/Project5/customer5.png";
import logo from "../../assests/Project5/customerlogo5.png";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import DemoCallSection from "../LandingPage/DemoCallSection";
import FooterSection from "../FooterSection";
import { Helmet } from "react-helmet";

const ImagePreview = ({ isOpen, image, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div className="relative max-w-[90vw] max-h-[90vh]">
        <button
          onClick={onClose}
          className="absolute -top-8 -right-5 text-white hover:text-gray-300"
        >
          <div className="flex justify-center items-center bg-gray-200 rounded-full p-1">
            <IoMdClose className="w-5 h-5 text-gray-600" />
          </div>
        </button>
        <img
          src={image}
          alt="Preview"
          className="max-w-full max-h-[85vh] object-contain"
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

const Project5 = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const tags = ["Market Research", "Ideation", "UI/UX Design"];

  const projectInfo = [
    { label: "Industry", value: "Accounting" },
    { label: "Location", value: "Switzerland" },
    { label: "Client", value: "Mokanan Parthipan" },
    { label: "Time", value: "3 weeks" },
  ];

  const objectivesList = [
    "Allow the auditing firm to review, approve, or reject requests quickly, saving time and reducing paperwork.",
    "Enable clients to easily submit requests with all necessary details, such as the amount, description, and type of request.",
    "Only registered companies can create requests, but they can send money to any recipient, registered or not.",
    "Provide a single place to access employee details, manage roles, and suspend employees if needed.",
  ];

  const projectSamples = [
    { image: project5Sample1, alt: "project5Sample1" },
    { image: project5Sample2, alt: "project5Sample2" },
    { image: project5Sample3, alt: "project5Sample3" },
  ];

  return (
    <>
      <Helmet>
        <title>Bellize - Accounts Management Solution for Auditing Firm</title>
        <meta
          name="description"
          content="Read our case study on how Cogntix developed a centralized platform for Bellize, an accounts management solution for auditing firms, to improve financial request management, approvals, and employee record tracking. "
        />
        <meta
          name="keywords"
          content="Auditing firm case study, Cogntix case study, Auditing firm software"
        />
      </Helmet>
      <Navbar />
      <div className="bg-white lg:mt-[150px] mt-[100px] lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <div
          onClick={() => handleNavigate("/ourWorks")}
          className="flex felx-row gap-3 items-center group cursor-pointer"
        >
          <PiCaretLeftBold className="text-xl text-gray-500 group-hover:text-secondary" />
          <p className="text-lg text-gray-500 group-hover:text-secondary">
            Back to Our works
          </p>
        </div>
        <div className="justify-start mt-4">
          <img src={logo} alt="logo" />
          <div className="mt-10 flex flex-wrap gap-3 md:w-[40%] w-full">
            {tags.map((tag, index) => (
              <div
                key={index}
                className="bg-gray-200 inline-flex justify-center items-center rounded-3xl py-1 px-4 text-primary font-medium text-sm whitespace-nowrap"
              >
                {tag}
              </div>
            ))}
          </div>
          <h1 className="ourWorkHeading mb-6 mt-4 lg:w-[90%]">
            Bellize -Accounts Management Solution for Auditing Firm
          </h1>
          <p className="lg:w-[65%] text-text2 text-lg">
            A platform that would enable their clients to streamline financial
            requests and review and approve payments, all in one centralized
            system.
          </p>
          <div className="mt-12 lg:w-[80%] grid md:grid-cols-4 grid-cols-2 lg:gap-12 gap-8">
            {projectInfo.map((info, index) => (
              <div key={index}>
                <p className="text-sm text-gray-700 uppercase">{info.label}</p>
                <p className="text-xl text-text4 font-semibold font-inter">
                  {info.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full lg:mt-14 mt-10 lg:px-[90px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <img
          src={projectDetail5}
          alt="projectDetail5"
          className="w-full h-full"
        />
      </div>

      <div className="lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2 my-20">
        <div className="flex flex-col gap-20">
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Background</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  Bellize - Accounts Management Solution was created to make
                  accounting and financial request management easier for an
                  auditing firm that serves multiple companies. In this setup,
                  registered client companies can submit requests to send
                  payments, and the auditing firm can review, approve, or reject
                  these requests based on specific details like the amount,
                  purpose, and request type. This platform also provides
                  features to manage employee information, such as adding,
                  updating, or suspending employee details in one place.
                </span>
                <br />
                <span>
                  Before this platform, the auditing firm struggled to manage a
                  high volume of financial requests and employee records
                  efficiently. With our help, it transformed these processes
                  into a secure, user-friendly platform.
                </span>
              </p>
            </div>
          </div>

          {/* Objective Section */}
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Objective</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  The main objectives of the Bellize - Accounts Management
                  Solution for Auditing Firm were to:
                </span>
                <br />
              </p>
              <div className="flex flex-col gap-6 mt-10">
                {objectivesList.map((objective, index) => (
                  <div key={index} className="flex flex-row  gap-3">
                    <div>
                      <FaCheck className="size-6 text-secondary mt-1" />
                    </div>
                    <div>
                      <p className="text-lg text-text2">{objective}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Solution Section - Not using map as requested */}
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Solution</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full text-lg text-text2">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  We worked closely with the client team to create a simple
                  platform that helps businesses manage financial requests. The
                  Financial Request Processing Module allows companies to easily
                  submit, review, and approve financial requests with all the
                  important details, such as amounts and recipient information.
                  This system keeps everything organized and transparent. It
                  helps businesses track employee information and quickly change
                  employee statuses when needed. The platform also allows
                  businesses to prioritize requests based on urgency, ensuring
                  fast action on important tasks.
                </span>
                <br />
                <span>
                  Overall, the solution has made financial operations faster,
                  more secure, and easier reducing manual tasks and improving
                  transparency, so businesses can focus on their main goals.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col mt-20 w-full gap-5 lg:max-h-[500px]">
        {projectSamples.map((image, index) => (
          <img
            key={index}
            src={image.image}
            alt={image.alt}
            className="w-full cursor-pointer transition-transform hover:scale-[1.02]"
            onClick={() => setSelectedImage(image.image)}
          />
        ))}
      </div>

      <ImagePreview
        isOpen={!!selectedImage}
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
      />

      <div className="bg-gray-100 mt-24 p-5">
        <div className="w-full lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
          <div className="w-[90%] mx-auto rounded-r-3xl rounded-l-[30px] mt-4">
            <div className="w-full flex lg:flex-row flex-col lg:gap-0 gap-4">
              <div className="lg:w-[35%]">
                <img src={customer} alt="customer" className="w-full h-full object-cover rounded-3xl" />
              </div>
              <div className="lg:w-[65%] flex flex-col items-start justify-center lg:px-[50px] px-3 lg:py-[50px] py-3">
                <img src={logo} alt="logo" />
                <p className="my-8 text-lg text-text4">
                  "We were very satisfied with the work of Cogntix on our new
                  portal design. <br />
                  They are available, responsive, on-time and professional."
                </p>
                <h2 className="font-semibold text-xl text-text4 mb-1">
                  Mokanan Parthipan
                </h2>
                <p className="text-text3 italic">CEO of Bellize</p>
                <h2 className="text-secondary uppercase">Switzerland</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DemoCallSection />
      <FooterSection />
    </>
  );
};

export default Project5;
