import React from 'react';
import { IoExtensionPuzzleSharp } from 'react-icons/io5';
import { BsRocketTakeoffFill } from 'react-icons/bs';
import { GoGoal } from 'react-icons/go';

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-start p-6 w-full ">
    <Icon className="w-10 h-10 text-primary mb-8" />
    <h3 className="text-lg font-bold text-primary mb-4">
      {title}
    </h3>
    <p className="text-text2 text-base">
      {description}
    </p>
  </div>
);

const Divider = () => (
  <div className="flex items-center justify-center">
    <div className="hidden md:block h-[280px] w-px bg-gray-300 mx-8" />
    <div className="md:hidden w-full h-px bg-gray-300 my-8" />
  </div>
);

const FeaturesSection = () => {
  const features = [
    {
      icon: IoExtensionPuzzleSharp,
      title: "Tailored Solutions",
      description: "Every challenge is unique, and we recognize that. Our solutions are specifically designed to address the distinct needs of each client, delivering real results."
    },
    {
      icon: BsRocketTakeoffFill,
      title: "On-Time Delivery",
      description: "We prioritize your timelines and recognize the importance of deadlines. Our agile development approach guarantees projects are finished promptly, maintaining high-quality standards."
    },
    {
      icon: GoGoal,
      title: "Customer-Centricity",
      description: "We prioritize the needs and experiences of our users, ensuring that our products are intuitive, accessible, and designed for real-world impact."
    }
  ];

  return (
    <section className="w-full py-12 md:py-16">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row items-stretch justify-center md:items-start">
          {features.map((feature, index) => (
            <React.Fragment key={feature.title}>
              <div className="flex-1">
                <FeatureCard {...feature} />
              </div>
              {index < features.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;