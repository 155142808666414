import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { PiCaretLeftBold } from "react-icons/pi";
import projectDetail6 from "../../assests/Project6/ProjectDetail6.png";
import project6Sample1 from "../../assests/Project6/project6sample1.png";
import project6Sample2 from "../../assests/Project6/project6sample2.png";
import project6Sample3 from "../../assests/Project6/project6sample3.png";
import customer from "../../assests/Project6/customer6.png";
import logo from "../../assests/Project6/customerlogo6.png";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import DemoCallSection from "../LandingPage/DemoCallSection";
import FooterSection from "../FooterSection";
import { Helmet } from "react-helmet";

const ImagePreview = ({ isOpen, image, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div className="relative max-w-[90vw] max-h-[90vh]">
        <button
          onClick={onClose}
          className="absolute -top-8 -right-5 text-white hover:text-gray-300"
        >
          <div className="flex justify-center items-center bg-gray-200 rounded-full p-1">
            <IoMdClose className="w-5 h-5 text-gray-600" />
          </div>
        </button>
        <img
          src={image}
          alt="Preview"
          className="max-w-full max-h-[85vh] object-contain"
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

const Project6 = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const tags = ["Market Research", "Ideation", "UI/UX Design"];

  const projectInfo = [
    { label: "Industry", value: "Logistics" },
    { label: "Location", value: "Switzerland" },
    { label: "Client", value: "Sinthusan Tharmaseelan" },
    { label: "Time", value: "1 month" },
  ];

  const objectivesList = [
    "Streamline the workflow to reduce manual intervention and improve order processing speed.",
    "Offer customers a portal to track parcels and view delivery partner details without requiring authentication.",
    "Assign delivery partners to orders easily and track their progress.",
    "Simplify the overall delivery logistics for both customers and businesses without the need for technical expertise.",
  ];

  const projectSamples = [
    { image: project6Sample1, alt: "project6Sample1" },
    { image: project6Sample2, alt: "project6Sample2" },
    { image: project6Sample3, alt: "project6Sample3" },
  ];

  return (
    <>
      <Helmet>
        <title>Parcel Delivery Management Platform for Swiss Last-mile Delivery Service</title>
        <meta
          name="description"
          content="Read how Cogntix helped a logistics company streamline their parcel delivery process with a custom Delivery Management System. Explore the solution that improved order tracking, delivery partner assignments, and customer satisfaction. "
        />
        <meta
          name="keywords"
          content="Logistic company, Cogntix case study, Logistics Industry Solution, Delivery Management System, Parcel Delivery Management, Logistics Software Solution"
        />
      </Helmet>
      <Navbar />
      <div className="bg-white lg:mt-[150px] mt-[100px] lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <div
          onClick={() => handleNavigate("/ourWorks")}
          className="flex felx-row gap-3 items-center group cursor-pointer"
        >
          <PiCaretLeftBold className="text-xl text-gray-500 group-hover:text-secondary" />
          <p className="text-lg text-gray-500 group-hover:text-secondary">
            Back to Our works
          </p>
        </div>
        <div className="justify-start mt-4">
          <img src={logo} alt="logo" />
          <div className="mt-10 flex flex-wrap gap-3 md:w-[40%] w-full">
            {tags.map((tag, index) => (
              <div
                key={index}
                className="bg-gray-200 inline-flex justify-center items-center rounded-3xl py-1 px-4 text-primary font-medium text-sm whitespace-nowrap"
              >
                {tag}
              </div>
            ))}
          </div>
          <h1 className="ourWorkHeading mb-6 mt-4 lg:w-[90%]">
            Parcel Delivery Management Platform for Swiss Last-mile Delivery
            Service
          </h1>
          <p className="lg:w-[65%] text-text2 text-lg">
            We were approached to design a Delivery Management System that
            simplifies the delivery process, from order acceptance to parcel
            tracking.
          </p>
          <div className="mt-12 lg:w-[90%] grid md:grid-cols-4 grid-cols-2 lg:gap-12 gap-8">
            {projectInfo.map((info, index) => (
              <div key={index}>
                <p className="text-sm text-gray-700 uppercase">{info.label}</p>
                <p className="text-xl text-text4 font-semibold font-inter">
                  {info.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full lg:mt-14 mt-10 lg:px-[90px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <img
          src={projectDetail6}
          alt="projectDetail6"
          className="w-full h-full"
        />
      </div>

      <div className="lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2 my-20">
        <div className="flex flex-col gap-20">
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Background</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  Our client, a Switzerland-based logistics company, faced
                  significant challenges in managing and tracking delivery
                  requests for their parcel delivery business. They were dealing
                  with inefficiencies in organizing delivery orders, assigning
                  delivery partners, and providing parcel tracking information
                  to customers. The existing system lacked an easy way to track
                  parcel status, and there was no centralized platform for both
                  customers and delivery partners to stay updated on deliveries.
                  The company wanted a solution from us that would streamline
                  the entire delivery process while being user-friendly,
                  reliable, and efficient.
                </span>
                <br />
              </p>
            </div>
          </div>

          {/* Objective Section */}
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Objective</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  The primary goal was to build a robust Delivery Management
                  System (DMS) to help the business manage delivery orders,
                  track parcels and assign tasks to delivery partners
                  seamlessly.
                </span>
                <br />
              </p>
              <div className="flex flex-col gap-6 mt-10">
                {objectivesList.map((objective, index) => (
                  <div key={index} className="flex flex-row  gap-3">
                    <div>
                      <FaCheck className="size-6 text-secondary mt-1" />
                    </div>
                    <div>
                      <p className="text-lg text-text2">{objective}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Solution Section - Not using map as requested */}
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Solution</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full text-lg text-text2">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  Cogntix developed a simple and efficient system to help the
                  logistics company streamline their entire delivery process.
                  The order management feature allows the team to accept,
                  organize, and manage delivery requests, making operations
                  smoother. Also, it gives customers updates via a tracking
                  link, ensuring transparency. Furthermore, it makes it easy to
                  assign a dedicated delivery partner to each order, speeding up
                  the process and increasing accountability. The portal for
                  customers and delivery partners allows easy access to tracking
                  and delivery details without the need for authentication.
                </span>
                <br />
                <span>
                  Throughout this project, Cogntix worked closely with the
                  client team to understand their challenges and customize the
                  system to their needs. This solution led to faster deliveries,
                  reduced operational errors, and improved customer
                  satisfaction.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col mt-20 w-full gap-5 lg:max-h-[500px]">
        {projectSamples.map((image, index) => (
          <img
            key={index}
            src={image.image}
            alt={image.alt}
            className="w-full cursor-pointer transition-transform hover:scale-[1.02]"
            onClick={() => setSelectedImage(image.image)}
          />
        ))}
      </div>

      <ImagePreview
        isOpen={!!selectedImage}
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
      />

      <div className="bg-gray-100 mt-24 p-5">
        <div className="w-full lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
          <div className="w-[90%] mx-auto rounded-r-3xl rounded-l-[30px] mt-4">
            <div className="w-full flex lg:flex-row flex-col lg:gap-0 gap-4">
              <div className="lg:w-[35%]">
                <img src={customer} alt="customer" className="w-full h-full object-cover rounded-3xl" />
              </div>
              <div className="lg:w-[65%] flex flex-col items-start justify-center lg:px-[50px] px-3 lg:py-[50px] py-3">
                <img src={logo} alt="logo" />
                <p className="my-8 text-lg text-text4">
                  "The Cogntix team truly understands your project's vision and
                  seamlessly integrates into your team, making the collaboration
                  feel natural. Their technological expertise consistently
                  delivers top-notch results."
                </p>
                <h2 className="font-semibold text-xl text-text4 mb-1">
                  Sinthusan Tharmaseelan
                </h2>
                <p className="text-text3 italic">CEO of Ingoude</p>
                <h2 className="text-secondary uppercase">Switzerland</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DemoCallSection />
      <FooterSection />
    </>
  );
};

export default Project6;
