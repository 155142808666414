import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";

const Values = () => {
  return (
    <>
      <div className="flex lg:flex-row flex-col gap-6 lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] my-20 px-3">
        <div className="flex flex-col lg:w-[45%] items-start">
          <h2 className="semiHeading text-left">OUR VALUES</h2>
          <h2 className="heading1 mb-6 lg:w-[450px]">What do we look for..</h2>
          <p className="lg:w-[95%] text-text2 text-lg mb-8">
            If you're enthusiastic, innovative, and eager to make a difference,
            you might be just who we’re looking for! Here are some qualities
            that thrive in our team
          </p>
          <a
            href="https://www.linkedin.com/company/cogntix/"
            target="_blank"
            rel="noopener noreferrer"
            className="group bg-primary rounded-lg px-6 py-3 text-white text-lg lg:w-[50%] flex flex-row gap-3 items-center justify-center w-full mb-8 no-underline"
          >
            Explore current openings
            <FaArrowRightLong className="mt-1 size-5 group-hover:inline hidden transition-all" />
          </a>
        </div>
        <div className="lg:w-[55%]">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6 w-full h-full">
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h3 className="text-black text-lg font-bold mb-6">Team Player</h3>
              <p className="text-black text-lg">
                You work well with others, inspire collaboration, and bring out
                the best in your teammates.
              </p>
            </div>
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h3 className="text-black text-lg font-bold mb-6">
                Takes Ownership
              </h3>
              <p className="text-black text-lg">
                You take pride in your work, own your responsibilities, and
                strive for excellence.
              </p>
            </div>
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h3 className="text-black text-lg font-bold mb-6">
                Helping Spirit
              </h3>
              <p className="text-black text-lg">
                You enjoy uplifting others and fostering a culture of kindness
                and support.
              </p>
            </div>
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h3 className="text-black text-lg font-bold mb-6">
                Positive Energy
              </h3>
              <p className="text-black text-lg">
                You make work enjoyable, inspiring others with your positive
                attitude and enthusiasm.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Values;
