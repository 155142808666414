import React from "react";
import { IoExtensionPuzzleSharp } from "react-icons/io5";
import { HiLightBulb } from "react-icons/hi";
import { FaChartPie } from "react-icons/fa";
import { HiClipboardList } from "react-icons/hi";
import { MdDesignServices } from "react-icons/md";
import { MdFactCheck } from "react-icons/md";

const FeaturesEngineering = () => {
  const FeaturesEngineeringData = [
    {
      icon: HiClipboardList,
      title: "UI/UX Audit",
      description:
        "Conduct a comprehensive review of your product's interface and user experience to identify pain points and opportunities for improvement.",
    },
    {
      icon: IoExtensionPuzzleSharp,
      title: "User Research & Wireframing",
      description:
        "Our research and structured wireframing help for impactful experiences. We dive into user behaviors, ensuring your product starts with a clear roadmap.",
    },
    {
      icon: FaChartPie,
      title: "User Experience Design",
      description:
        "Craft journeys that feel seamless, and engaging. Our UX design aligns functionality with user expectations, creating satisfaction at every click.",
    },
    {
      icon: MdDesignServices,
      title: "User Interface Design",
      description:
        "Craft intuitive and visually appealing designs that align with your brand's vision while ensuring seamless user interactions across all platforms.",
    },
    {
      icon: MdFactCheck,
      title: "Digital Prototyping & Testing",
      description:
        "Bring ideas to life quickly with prototypes. We ensure every feature works flawlessly before launch, using real feedback to perfect the experience.",
    },
    {
      icon: HiLightBulb,
      title: "UX Strategy & Consulting",
      description:
        "Navigate the digital landscape with UX strategies tailored to your goals. We guide to make informed design decisions that enhance user satisfaction.",
    },
  ];

  return (
    <div className="bg-primary">
      <div className="flex flex-col lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] py-16 px-3 bg-primary">
        <div className="flex flex-col justify-center">
          <h2 className="semiHeading text-center">PRODUCT DESGIN SERVICES</h2>
          <h2 className="md:text-6xl text-[30px] text-white font-bold mb-8 text-center">
            User-Centered Design Solutions
          </h2>
          <p className="text-center text-white text-lg mb-16 lg:mx-auto mx-3 lg:max-w-[880px]">
            Building user-friendly designs that solve real problems with
            seamless and effective solutions.
          </p>
        </div>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-6">
          {FeaturesEngineeringData.map((feature, index) => {
            const Icon = feature.icon;
            return (
              <div
                key={index}
                className="relative shadow-md rounded-2xl border-[1px] border-gray-700 p-6 flex flex-col items-start 
                        overflow-hidden cursor-pointer group
                        transition-all duration-500 ease-in-out
                        hover:shadow-2xl transform hover:scale-105
                        before:absolute before:inset-0 
                        before:opacity-0 before:transition-opacity before:duration-500
                        hover:before:opacity-5"
              >
                {/* Animated border effect */}
                <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                  <div className="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-secondary to-transparent animate-shimmer"></div>
                  <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-secondary to-transparent animate-shimmer"></div>
                  <div className="absolute inset-y-0 left-0 w-px bg-gradient-to-b from-transparent via-secondary to-transparent animate-shimmer"></div>
                  <div className="absolute inset-y-0 right-0 w-px bg-gradient-to-b from-transparent via-secondary to-transparent animate-shimmer"></div>
                </div>

                <Icon
                  className="size-10 text-white group-hover:text-secondary mb-9 relative z-10
                             transition-all duration-500 ease-in-out
                             group-hover:scale-110"
                />

                <p
                  className="text-white text-lg font-bold mb-4 relative z-10
                          transition-all duration-500
                          group-hover:text-secondary group-hover:translate-x-2"
                >
                  {feature.title}
                </p>

                <p
                  className="text-gray-400 text-lg relative z-10
                          transition-all duration-500 group-hover:text-gray-100"
                >
                  {feature.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const styleTag = document.createElement("style");
styleTag.textContent = `
  @keyframes shimmer {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  .animate-shimmer {
    animation: shimmer 2s infinite;
  }
`;
document.head.appendChild(styleTag);

export default FeaturesEngineering;
