import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };
  return (
    <>
      <div className="flex lg:flex-row flex-col gap-6 lg:p-[100px]   larger:px-[200px] mx-auto max-w-[2000px] my-20 px-3">
        <div className="flex flex-col lg:w-[45%] items-start">
          <h2 className="semiHeading text-left">OUR VALUES</h2>
          <h2 className="heading1 mb-6">Drive Growth with Top Talents</h2>
          <p className="lg:w-[95%] text-text2 text-lg mb-8">
            Collaborate with our highly skilled engineers who align perfectly
            with your vision, empowering your business to reach new heights.
          </p>
          <button
            onClick={() => handleNavigate("/subscriptions")}
            className="group bg-primary rounded-lg px-6 py-3 text-white text-lg lg:w-[40%] w-full mb-8 flex justify-center items-center gap-3"
          >
            <p>Explore Our Plans</p>
            <FaArrowRightLong className="group-hover:inline hidden" />
          </button>
        </div>
        <div className="lg:w-[55%]">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6 w-full h-full">
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h3 className="text-black text-lg font-bold mb-6">
                Extend Your Core Team
              </h3>
              <p className="text-black text-lg">
                Grow your team with offshore services and expand into new
                markets, driving your business growth forward.
              </p>
            </div>
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h3 className="text-black text-lg font-bold mb-6">
                On-time Delivery
              </h3>
              <p className="text-black text-lg">
                We ensure your projects are delivered on schedule, ensuring that
                you meet your deadlines and market expectations.
              </p>
            </div>
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h3 className="text-black text-lg font-bold mb-6">
                End-to-End Support
              </h3>
              <p className="text-black text-lg">
                We offer complete support from the idea stage to launch your
                product, ensuring a seamless transition and successful outcome.
              </p>
            </div>
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h3 className="text-black text-lg font-bold mb-6">
                Faster Time-to-Market
              </h3>
              <p className="text-black text-lg">
                Our team helps you to launch your products faster, giving you a
                competitive edge & enabling you to respond quickly to market
                changes.
              </p>
            </div>
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h3 className="text-black text-lg font-bold mb-6">
                Stay at the Forefront
              </h3>
              <p className="text-black text-lg">
                Maximize your potential with engineers skilled in the latest
                technologies and top industry practices
              </p>
            </div>
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h3 className="text-black text-lg font-bold mb-6">
                Responsible & Ownership
              </h3>
              <p className="text-black text-lg">
                Our dedicated team takes responsibility for their work, ensuring
                your projects are handled with care and professionalism.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
