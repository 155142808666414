import React, { useState } from "react";
import naresh from "../../assests/Naresh.png";
import { FaArrowRightLong } from "react-icons/fa6";
import { HiMiniXMark } from "react-icons/hi2";
import { BsFillCheckCircleFill } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { uploadFormData } from "../../api/clientService";
import { MdErrorOutline } from "react-icons/md";

const Divider = () => (
  <div className="flex items-center justify-center lg:ml-8">
    <div className="hidden md:block h-full w-px bg-gray-700" />
    <div className="lg:hidden w-full h-px bg-gray-700 lg:mb-0 my-12" />
  </div>
);

const DotPattern = () => (
  <svg
    className="absolute inset-0 w-full h-full"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <pattern
        id="dotPattern"
        x="0"
        y="0"
        width="20"
        height="20"
        patternUnits="userSpaceOnUse"
      >
        <circle cx="1.5" cy="1.5" r="1.5" fill="rgba(255, 255, 255, 0.1)" />
      </pattern>
    </defs>
    <rect width="100%" height="100%" fill="url(#dotPattern)" />
  </svg>
);

const ContactModal = ({ isOpen, onClose }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    emailError: "",
    phone: "",
    message: "",
    timeline: "",
    percentage: "",
  });

  if (!isOpen) return null;

  const timeline = [
    "As soon as possible",
    "In 1 month",
    "In 3-6 months",
    "In 6-12 months",
    "Later than 12 months",
  ];

  const percentage = [
    "Very likely (>80%)",
    "Likely (50-80%)",
    "Just checking my options (10-50%)",
  ];

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const payloadData = {
      template: "generalContactInquiry",
      clientName: formData.name,
      clientEmail: formData.email,
      clientMobileNo: formData.phone,
      projectIdea: formData.message,
      startTime: formData.timeline,
      likelihood: formData.percentage,
    };

    try {
      // Simulate API call
      const response = await uploadFormData(payloadData);
      setIsSubmitting(false);
      setIsSubmitted(true);

      // Show success state for 2 seconds before closing
      setTimeout(() => {
        // Reset form and states
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
          timeline: "",
          percentage: "",
        });
        setIsSubmitted(false);
        onClose();
      }, 2000);
    } catch (error) {
      setIsSubmitting(false);
      console.error("Error submitting form:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Email validation
    if (name === "email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValidEmail = emailPattern.test(value);

      // Add error state to track validation
      setFormData((prev) => ({
        ...prev,
        email: value,
        emailError:
          !isValidEmail && value !== ""
            ? "Invalid Email Address. Please enter a valid Email Address !"
            : "",
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handlePhoneChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      phone: value,
    }));
  };

  return (
    <div
      onClick={handleOverlayClick}
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
    >
      <div className="relative rounded-3xl shadow-xl px-10 py-6 max-w-[900px] max-h-[600px] bg-white overflow-y-auto custom-scroll">
        <div className="sticky top-0 left-0 w-full flex justify-end -mt-2 lg:-mr-4 ml-5  pb-4">
          <div
            onClick={() => !isSubmitting && onClose()}
            className={`group p-1 ${
              isSubmitting
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-100 bg-gray-50 cursor-pointer"
            } rounded-full`}
          >
            <HiMiniXMark className="text-xl text-gray-600 group-hover:text-primary" />
          </div>
        </div>
        <div className="flex-col flex- items-start mb-10">
          <h2 className="semiHeading">CONTACT US</h2>
          <h1 className="text-primary lg:text-[36px] text-3xl font-extrabold">
            Let's Bring Your Idea to Live!
          </h1>
        </div>
        <div className="flex lg:flex-row flex-col gap-10">
          <div className="lg:w-[65%] flex flex-col">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="lg:min-w-[530px] w-full py-2 px-3 border-[1px] border-gray-400 rounded-md mb-6 focus:outline-none focus:ring-1 focus:ring-gray-300"
                placeholder="Name"
                required
              />

              <div className="mb-6">
                <div className="relative">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`lg:min-w-[530px] w-full py-2 px-3 border-[1px] ${
                      formData.emailError ? "border-red-500" : "border-gray-400"
                    } rounded-md focus:outline-none focus:ring-1 focus:ring-gray-300`}
                    placeholder="Email address"
                    required
                  />
                  {formData.emailError && (
                    <MdErrorOutline className="absolute right-3 top-1/2 -translate-y-1/2 text-red-500 text-xl" />
                  )}
                </div>
                {formData.emailError && (
                  <p className="text-red-500 text-sm mt-1 mb-2 font-medium">
                    {formData.emailError}
                  </p>
                )}
              </div>

              <div className="relative space-y-[0.5px] mb-6">
                <PhoneInput
                  country="lk"
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  enableSearch={true}
                  inputProps={{
                    name: "phone",
                    required: true,
                    className:
                      "mt-[-0.5px] lg:min-w-[530px] w-full pl-[50px] pr-[12px] py-2 border border-gray-400 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-300 mt-1",
                  }}
                  containerClass="!static"
                  buttonClass="!absolute !top-1/2 !-translate-y-1/2 !left-2 !z-10 !border-none !bg-white !size-8"
                  dropdownClass="!absolute !top-full !left-0 !mt-1"
                />
              </div>

              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="lg:min-w-[530px] w-full py-2 px-3 border-[1px] border-gray-400 rounded-md mb-4 focus:outline-none focus:ring-1 focus:ring-gray-300"
                rows={4}
                placeholder="Tell us a bit about your idea"
              />

              <div className="space-y-3 mb-6">
                <label
                  htmlFor="timeline"
                  className="block font-medium text-primary"
                >
                  When do you plan to start the project?
                </label>
                <div className="flex flex-wrap gap-3">
                  {timeline.map((option) => (
                    <label key={option} className="relative">
                      <input
                        required
                        type="radio"
                        name="timeline"
                        value={option}
                        checked={formData.timeline === option}
                        onChange={handleChange}
                        className="peer absolute opacity-0"
                      />
                      <div className="bg-gray-200 px-4 py-1 flex items-center justify-center rounded-full cursor-pointer peer-checked:bg-primary peer-checked:text-white transition-colors">
                        <span className="text-sm font-medium">{option}</span>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
              <div className="space-y-3 mb-10">
                <label
                  htmlFor="percentage"
                  className="block font-medium text-primary"
                >
                  How likely is your project to be implemented in next 12
                  months?
                </label>
                <div className="flex flex-wrap gap-3">
                  {percentage.map((option2) => (
                    <label key={option2} className="relative">
                      <input
                        required
                        type="radio"
                        name="percentage"
                        value={option2}
                        checked={formData.percentage === option2}
                        onChange={handleChange}
                        className="peer absolute opacity-0"
                      />
                      <div className="bg-gray-200 px-4 py-1 flex items-center justify-center rounded-full cursor-pointer peer-checked:bg-primary peer-checked:text-white transition-colors">
                        <span className="text-sm font-medium">{option2}</span>
                      </div>
                    </label>
                  ))}
                </div>
              </div>
              <div className="flex lg:flex-row flex-col-reverse lg:justify-between gap-4 lg:items-center mb-6">
                <a
                  href="https://cal.com/naresh-shanmugaraj/discussion"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 lg:text-left text-center hover:text-secondary"
                >
                  Prefer to schedule a call?
                </a>
                <button
                  type="submit"
                  disabled={isSubmitting || isSubmitted}
                  className={`py-3 px-6 rounded-md bg-primary 
           text-white font-semibold min-w-[120px] flex items-center justify-center gap-2 ${
             isSubmitting || isSubmitted ? "cursor-not-allowed" : ""
           }`}
                >
                  {isSubmitting ? (
                    <>
                      <div className="w-5 h-5 border-2 border-white border-t-secondary rounded-full animate-spin" />
                    </>
                  ) : isSubmitted ? (
                    <>
                      <BsFillCheckCircleFill className="text-green-600 size-[18px]" />
                      <span>Submitted!</span>
                    </>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
          <div className="lg:w-[35%] flex flex-col gap-6 items-start">
            <h2 className="text-primary text-2xl font-extrabold">
              Contact info
            </h2>
            <div>
              <h3 className="text-xs text-gray-500 font-medium">
                EMAIL ADDRESS
              </h3>
              <p className="text-lg text-primary font-semibold">
                hai@cogntix.com
              </p>
            </div>
            <div>
              <h3 className="text-xs text-gray-500 font-medium">
                PHONE NUMBER
              </h3>
              <p className="text-lg text-primary font-semibold">
                (+1) 205 316 9387
              </p>
              <p className="text-lg text-primary font-semibold">
                (+94) 77 444 1787
              </p>
            </div>
            <div>
              <h3 className="text-xs text-gray-500 font-medium">ADDRESS</h3>
              <p className="text-lg text-primary font-semibold">
                No 218, Stanley road,
                <br /> Jaffna, Sri Lanka.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DemoCallSection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="w-full bg-white flex flex-col lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] py-12">
      <div className="relative bg-primary shadow-md w-full lg:p-14 px-3 py-16 flex md:flex-row flex-col lg:rounded-2xl mb-8 overflow-hidden">
        <DotPattern />
        <div className="relative lg:w-[60%] bg-transparent flex flex-col items-start justify-center">
          <h2 className="text-4xl text-white font-bold mb-6 max-w-[600px]">
            Ready to discuss your project? Reach out to us!
          </h2>
          <p className="text-white text-lg lg:mb-0 mb-8 lg:max-w-[640px] tab:max-w-[500px]">
            We love connecting with solution builders around the globe! If
            you’re curious about building digital products or have other
            inquiries, get in touch with us!
          </p>
          <div className="w-full flex md:flex-row flex-col md:gap-6 gap-3 mt-6">
            <a
              href="https://cal.com/naresh-shanmugaraj/discussion"
              target="_blank"
              rel="noopener noreferrer"
              className="group min-w-[255px] tab:max-w-[300px] flex flex-row gap-3 items-center justify-center py-3 px-10 bg-white rounded-lg text-primary font-medium"
            >
              Schedule a meeting
              <FaArrowRightLong className="text-black size-5 group-hover:inline hidden transition-all" />
            </a>
            <button
              className="group min-w-[200px] tab:max-w-[300px] flex flex-row gap-3 items-center justify-center py-3 px-10 bg-primary rounded-lg text-white font-medium border-[1px] border-white"
              onClick={() => setIsModalOpen(true)}
            >
              Contact us
              <FaArrowRightLong className="size-5 group-hover:inline hidden transition-all" />
            </button>
          </div>
        </div>
        <Divider />
        <div className="relative md:w-[40%] bg-transparent flex flex-col justify-center items-center">
          <img src={naresh} alt="naresh" />
          <h2 className="font-semibold text-2xl text-white mt-6 mb-1">
            Naresh Shanmugaraj
          </h2>
          <p className="text-gray-300 italic mb-1">CEO & Founder</p>
          <h2 className="text-secondary">
            <a href="mailto:naresh@cogntix.com">naresh@cogntix.com</a>
          </h2>
        </div>
      </div>

      <ContactModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default DemoCallSection;
