import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";

const DotPattern = () => (
  <svg
    className="absolute inset-0 w-full h-full"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <pattern
        id="dotPattern"
        x="0"
        y="0"
        width="20"
        height="20"
        patternUnits="userSpaceOnUse"
      >
        <circle cx="1.5" cy="1.5" r="1.5" fill="rgba(255, 255, 255, 0.1)" />
      </pattern>
    </defs>
    <rect width="100%" height="100%" fill="url(#dotPattern)" />
  </svg>
);

const CurrentOpeningsCard = () => {
  return (
    <div className="w-full bg-white flex flex-col lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] py-12">
      <div className="relative bg-primary shadow-md w-full lg:p-14 px-3 py-16 flex flex-col justify-center lg:rounded-2xl mb-8 overflow-hidden">
        <DotPattern />
        <div className="relative bg-transparent flex flex-col justify-center">
          <h2 className="text-4xl text-white font-bold text-center mb-3">
            Visit our LinkedIn to Explore Latest Job Openings
          </h2>
          <p className="text-white flex justify-center text-center text-lg lg:mb-0 mb-8">
            We’re always looking for talented people. Be a part of a
            multidisciplinary and talented team,
            <br className="lg:inline hidden" /> creating creative solutions.
          </p>
          <div className="w-full flex justify-center items-center mt-6">
            <a
              href="https://www.linkedin.com/company/cogntix/"
              target="_blank"
              rel="noopener noreferrer"
              className="group md:max-w-[300px] w-full flex flex-row gap-3 items-center justify-center py-3 px-10 bg-white rounded-lg text-primary font-medium"
            >
              Explore current openings
              <FaArrowRightLong className="mt-1 text-black size-5 group-hover:inline hidden transition-all" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentOpeningsCard;
