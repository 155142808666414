import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };
  return (
    <>
      <div className="flex lg:flex-row flex-col gap-6 lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] my-20 px-3">
        <div className="flex flex-col lg:w-[45%] items-start">
          <h2 className="semiHeading text-left">OUR VALUES</h2>
          <h2 className="heading1 mb-6">
            Your Partner for Outstanding User Experience
          </h2>
          <p className="lg:w-[95%] text-text2 text-lg mb-8">
            Start your journey toward exceptional UI/UX design with us, where we
            prioritize your brand's vision and user needs for optimal results.
          </p>
          <button
            onClick={() => handleNavigate("/subscriptions")}
            className="group bg-primary rounded-lg px-6 py-3 text-white text-lg lg:w-[40%] w-full mb-8 flex justify-center items-center gap-3"
          >
            <p>Explore Our Plans</p>
            <FaArrowRightLong className="group-hover:inline hidden" />
          </button>
        </div>
        <div className="lg:w-[55%]">
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6 w-full h-full">
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h4 className="text-black text-lg font-bold mb-6">
                Stay Ahead of Trends
              </h4>
              <p className="text-black text-lg">
                Our modern, responsive designs align with industry standards,
                keeping your brand standout from your competitors.
              </p>
            </div>
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h4 className="text-black text-lg font-bold mb-6">
                End-to-End Design Support
              </h4>
              <p className="text-black text-lg">
                From concept to delivery, we provide ongoing support to ensure
                your product remains impactful and user-friendly.
              </p>
            </div>
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h4 className="text-black text-lg font-bold mb-6">User Focused</h4>
              <p className="text-black text-lg">
                We prioritize user needs, crafting experiences that enhance
                satisfaction and foster long-term loyalty.
              </p>
            </div>
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h4 className="text-black text-lg font-bold mb-6">
                Feedback-Driven
              </h4>
              <p className="text-black text-lg">
                We leverage user feedback to refine designs continually,
                ensuring alignment with user expectations and preferences.
              </p>
            </div>
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h4 className="text-black text-lg font-bold mb-6">
                Performance Optimization
              </h4>
              <p className="text-black text-lg">
                We optimize designs for speed and efficiency, enhancing user
                experience and reducing load times.
              </p>
            </div>
            <div className=" rounded-2xl  border-[1px] border-gray-300 p-6">
              <h4 className="text-black text-lg font-bold mb-6">
                Competitive Edge
              </h4>
              <p className="text-black text-lg">
                Innovative UI/UX strategies give you a competitive advantage,
                helping your brand stand out in the marketplace.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
