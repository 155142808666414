import React from "react";
import teamImage1 from "../../assests/TeamImage1.png";
import teamImage2 from "../../assests/TeamImage2.png";
import teamImage3 from "../../assests/TeamImage3.png";
import teamImage4 from "../../assests/TeamImage4.png";
import teamImage5 from "../../assests/TeamImage5.png";
import teamImage6 from "../../assests/TeamImage6.png";
import teamImage7 from "../../assests/TeamImage7.png";
import teamImage8 from "../../assests/TeamImage8.png";

const TeamImages = () => {
  return (
    <>
      <div className="mt-10 flex lg:flex-row md:hidden flex-col gap-6">
        <img src={teamImage1} alt="teamImage1" className="w-full h-full" />
        <img src={teamImage2} alt="teamImage2" className="w-full h-full" />
        <img src={teamImage3} alt="teamImage3" className="w-full h-full" />
        <img src={teamImage4} alt="teamImage4" className="w-full h-full" />
        <img src={teamImage5} alt="teamImage5" className="w-full h-full" />
        <img src={teamImage6} alt="teamImage6" className="w-full h-full" />
      </div>

      <div className="md:flex hidden mt-10 flex-col gap-6 larger:px-[200px] mx-auto max-w-[2000px]">
        <img src={teamImage7} alt="teamImage7" className="w-full h-full" />
        <img src={teamImage8} alt="teamImage8" className="w-full h-full" />
      </div>
    </>
  );
};

export default TeamImages;


