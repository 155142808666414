import React from "react";
import Slider from "react-slick";
import { PiCaretRightBold, PiCaretLeftBold } from "react-icons/pi";
import customer from "../../assests/customer1.png";
import customer2 from "../../assests/customer2.png";
import customer3 from "../../assests/customer3.png";
import customer4 from "../../assests/customer4.png";
import customer5 from "../../assests/customer5.png";
import customerlogo1 from "../../assests/customerlogo1.png";
import customerlogo2 from "../../assests/customerlogo2.png";
import customerlogo3 from "../../assests/customerlogo3.png";
import customerlogo4 from "../../assests/customerlogo4.png";
import customerlogo5 from "../../assests/customerlogo5.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PrevArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute left-4 top-1/2 z-10 -translate-y-1/2 bg-white p-2 rounded-full shadow-lg hover:bg-gray-50 group"
  >
    <PiCaretLeftBold className="text-gray-500 group-hover:text-secondary text-xl" />
  </button>
);

const NextArrow = ({ onClick }) => (
  <button
    onClick={onClick}
    className="absolute right-4 top-1/2 z-10 -translate-y-1/2 bg-white p-2 rounded-full shadow-lg hover:bg-gray-50 group"
  >
    <PiCaretRightBold className="text-gray-500 group-hover:text-secondary text-xl" />
  </button>
);

const Testimonials = () => {
  const testimonials = [
    {
      customerImage: customer,
      logoImage: customerlogo1,
      quote:
        "Thamili has been working with Cogntix for over a year now and we are very happy with their work, their flexibility and the overall results. We can highly recommend them",
      name: "Gobi Sivanthan",
      position: "Director of Thamili",
      location: "Denmark",
    },
    {
      customerImage: customer2,
      logoImage: customerlogo2,
      quote:
        "Excellent communication. Work was delivered according to and beyond brief. Very trustworthy, patient and they always bring good additional creative input to the project. Very happy with the collaboration.",
      name: "Gobiraj Karunananthan",
      position: "Gobiraj Karunananthan",
      location: "BELGIUM",
    },
    {
      customerImage: customer3,
      logoImage: customerlogo3,
      quote:
        "The Cogntix team provided valuable insight and experience into how to approach the product we were trying to build. Kalaikinesologie wouldn’t have experienced anything near the success we have without their help and contribution.",
      name: "Kalaiyarasy",
      position: "Founder of Kalaikinesologie",
      location: "FRANCE",
    },
    {
      customerImage: customer4,
      logoImage: customerlogo4,
      quote:
        "Very pleasant to work with Cogntix, they were open to our suggestions, and worked within our deadlines and our budget!",
      name: "Rama Sunatharasarma",
      position: "Co-founder of Recipe lab",
      location: "FRANCE",
    },
    {
      customerImage: customer5,
      logoImage: customerlogo5,
      quote:
        "We were very satisfied with the work of Cogntix on our new portal design. They are available, responsive, on-time and professional",
      name: "Mokanan Parthipan",
      position: "CEO of Bellize",
      location: "Switzerland",
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className="w-full flex flex-col lg:p-[100px]  larger:px-[200px] mx-auto max-w-[2000px] px-2 py-12">
      <div className="flex flex-col items-start">
        <h2 className="semiHeading text-left">TESTIMONIALS</h2>
        <h2 className="heading1 mb-6 text-left">Why Clients Choose Us</h2>
        <p className="text-left text-text2 text-lg mb-16 lg:w-[55%]">
          Don’t take our word for it. Listen to what our clients have to say
          before you come on board with us for your next project!
        </p>
      </div>

      <div className="w-full">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index}>
              <div className="w-[90%] mx-auto rounded-r-3xl rounded-l-[30px] mt-4">
                <div className="w-full flex lg:flex-row flex-col lg:gap-0 gap-4">
                  <div className="lg:w-[35%]">
                    <img
                      src={testimonial.customerImage}
                      alt={`${testimonial.name}`}
                      className="w-full h-full object-cover rounded-3xl"
                    />
                  </div>
                  <div className="lg:w-[65%] flex flex-col items-start justify-center lg:px-[50px] px-3 lg:py-[50px] py-3">
                    <img
                      src={testimonial.logoImage}
                      alt={`${testimonial.name}'s company logo`}
                    />
                    <p className="my-8 text-lg text-text4">
                      "{testimonial.quote}"
                    </p>
                    <h2 className="font-semibold text-xl text-text4 mb-1">
                      {testimonial.name}
                    </h2>
                    <p className="text-text3 italic">{testimonial.position}</p>
                    <h2 className="text-secondary uppercase">
                      {testimonial.location}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonials;
