import React from "react";
import { HiUserGroup } from "react-icons/hi";
import { FaClock } from "react-icons/fa6";
import { GoGoal } from "react-icons/go";
import { FaBolt } from "react-icons/fa";
import { LuRefreshCcw } from "react-icons/lu";
import { IoShieldCheckmarkSharp } from "react-icons/io5";

const Features = () => {
  const featuresData = [
    {
      icon: HiUserGroup,
      title: "Expert Designers & Developers",
      description:
        "Skilled team delivering creative, user-friendly products with top-tier expertise.",
    },
    {
      icon: FaClock,
      title: "On-Time Delivery",
      description:
        "We ensure timely delivery, keeping your project on track and on schedule.",
    },
    {
      icon: GoGoal,
      title: "Attention to Detail",
      description:
        "Every design element is carefully crafted for seamless user experiences.",
    },
    {
      icon: FaBolt,
      title: "Quick Turnaround Time",
      description:
        "We deliver high-quality work quickly, without compromising on standards.",
    },
    {
      icon: LuRefreshCcw,
      title: "Unlimited Revisions",
      description:
        "We offer unlimited revisions to ensure complete satisfaction with designs.",
    },
    {
      icon: IoShieldCheckmarkSharp,
      title: "Cancel Anytime",
      description: "You can cancel or change services as your needs change.",
    },
  ];

  return (
    <div className="flex flex-col lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] py-16 px-3">
      <div className="flex flex-col justify-center">
        <h2 className="semiHeading text-center">WHAT DO YOU GET</h2>
        <h2 className="heading1 mb-6 text-center">
          Always deliver your expectation
        </h2>
        <p className="text-center text-text2 text-lg mb-16">
          You hire one Cogntix Talent, but you get so much more
        </p>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-6">
        {featuresData.map((feature, index) => {
          const Icon = feature.icon;
          return (
            <div
              key={index}
              className="relative border-[1px] border-gray-200 rounded-2xl p-6 flex flex-col items-start 
                        overflow-hidden cursor-pointer group
                        transition-all duration-500 ease-in-out
                        hover:shadow-2xl transform hover:scale-105
                        before:absolute before:inset-0 
                        before:opacity-0 before:transition-opacity before:duration-500
                        hover:before:opacity-5"
            >
              {/* Animated border effect */}
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                <div className="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-secondary to-transparent animate-shimmer"></div>
                <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-secondary to-transparent animate-shimmer"></div>
                <div className="absolute inset-y-0 left-0 w-px bg-gradient-to-b from-transparent via-secondary to-transparent animate-shimmer"></div>
                <div className="absolute inset-y-0 right-0 w-px bg-gradient-to-b from-transparent via-secondary to-transparent animate-shimmer"></div>
              </div>

              <Icon
                className="size-10 text-secondary mb-9 relative z-10
                             transition-all duration-500 ease-in-out
                             group-hover:scale-110"
              />

              <p
                className="text-black text-lg font-bold mb-4 relative z-10
                          transition-all duration-500
                          group-hover:text-secondary group-hover:translate-x-2"
              >
                {feature.title}
              </p>

              <p
                className="text-black text-lg relative z-10
                          transition-all duration-500 group-hover:text-gray-700"
              >
                {feature.description}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styleTag = document.createElement("style");
styleTag.textContent = `
  @keyframes shimmer {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(100%);
    }
  }

  .animate-shimmer {
    animation: shimmer 2s infinite;
  }
`;
document.head.appendChild(styleTag);

export default Features;
