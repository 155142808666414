import React from "react";
import Navbar from "../components/Navbar";
import FooterSection from "../components/FooterSection";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <meta
          name="description"
          content="We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website."
        />
        <meta name="keywords" content="Privacy policy, Terms & Conditions" />
      </Helmet>
      <Navbar />
      <div className="lg:mt-[140px] mt-[120px] lg:px-[100px] larger:px-[200px] larger:mx-auto max-w-[2000px] mx-2 flex flex-col items-start">
        <h1 className="heading1 mb-6">Privacy policy</h1>
        <p className="text-gray-600 text-lg mb-10">
          Last updated: October 31, 2024
        </p>
      </div>
      <div className="lg:px-[100px] larger:px-[200px] larger:mx-auto max-w-[2000px] mx-2 mb-20 flex flex-col gap-10 items-start">
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            1. General Information
          </h2>
          <p className="text-gray-600 mb-6">
            Cogntix is dedicated to protecting the privacy of individuals and
            safeguarding personal data from misuse. In line with Sri Lankan data
            protection laws, as well as GDPR compliance for users within the
            European Union, we strive to ensure your personal data is handled
            securely and responsibly.
          </p>
          <p className="text-gray-600 mb-3">This policy outlines:</p>
          <ul className="list-disc pl-5 space-y-2">
            <li className="text-gray-600">
              How personal data is collected, processed, and used.
            </li>
            <li className="text-gray-600">Our data protection measures.</li>
            <li className="text-gray-600">Your rights as a data subject.</li>
          </ul>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            2. Data Security and Transmission
          </h2>
          <p className="text-gray-600">
            We work with hosting providers to secure our databases and prevent
            unauthorized access, data loss, or misuse. However, please note that
            data transmission over the internet, such as via email, may have
            security vulnerabilities, and it is not possible to ensure complete
            protection against third-party access.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            3. Collection and Processing of Personal Data
          </h2>
          <p className="text-gray-600 mb-6">
            Personal data includes any information relating to an identified or
            identifiable person. When using our website, you may voluntarily
            provide information such as your name, email, and other contact
            details. This data is collected and processed only with your consent
            and for specific purposes outlined below.
          </p>
          <ul className="list-disc pl-5 space-y-2">
            <li className="text-gray-600">
              <span className="font-bold">Statistical Data:</span> When you
              visit our website, non-personally identifiable data (e.g., pages
              viewed, date, and time) is stored for statistical analysis. This
              information cannot be directly linked to an individual.
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Voluntary Data:</span> Personal data,
              such as name, email, or address, is collected only when
              voluntarily provided. We do not share your personal data with
              third parties without your explicit consent.
            </li>
          </ul>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            4. Legal Basis for Data Processing
          </h2>
          <p className="text-gray-600 mb-6">
            Cogntix processes personal data in compliance with both Sri Lankan
            and GDPR regulations. In accordance with GDPR, the legal bases for
            processing are:
          </p>
          <ul className="list-disc pl-5 space-y-2">
            <li className="text-gray-600">
              <span className="font-bold">Consent</span> (Art. 6(1)(a) GDPR):
              When you provide clear consent for us to process your personal
              data for a specific purpose.
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Contractual Obligation</span> (Art.
              6(1)(b) GDPR): Processing is necessary to fulfill a contract with
              you or to take pre-contractual measures at your request.
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Legal Obligation</span> (Art. 6(1)(c)
              GDPR): Processing is required to comply with applicable legal
              obligations.
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Protection of Vital Interests</span>{" "}
              (Art. 6(1)(d) GDPR): Processing is necessary to protect someone's
              life or well-being.
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Legitimate Interests</span> (Art.
              6(1)(f) GDPR): Processing is necessary for our legitimate business
              interests, except where such interests are overridden by your
              privacy rights.
            </li>
          </ul>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            5. Special Categories of Data
          </h2>
          <p className="text-gray-600 mb-6">
            For certain activities, such as the recruitment process, we may
            process special categories of personal data (e.g., health
            information for assessing work eligibility), in line with Article 9
            of the GDPR:
          </p>
          <ul className="list-disc pl-5 space-y-2 mb-6">
            <li className="text-gray-600">
              <span className="font-bold">
                Employment and Social Security Rights
              </span>
              (Art. 9(2)(b) GDPR)
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Vital Interests</span> (Art. 9(2)(c)
              GDPR)
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Healthcare Purposes</span>{" "}
              (Art.9(2)(h) GDPR)
            </li>
          </ul>
          <p className="text-gray-600">
            We process these categories only with your explicit consent or when
            necessary for a legitimate purpose.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            6. Retention Period of Personal Data
          </h2>
          <p className="text-gray-600">
            Personal data is stored only as long as necessary to fulfill the
            purpose for which it was collected or to meet legal retention
            requirements. After this period, your data will be securely deleted
            or anonymized.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            7. Data Subject Rights under GDPR
          </h2>
          <p className="text-gray-600 mb-6">
            As per GDPR, you have the following rights regarding your personal
            data:
          </p>
          <ul className="list-disc pl-5 space-y-2 mb-6">
            <li className="text-gray-600">
              <span className="font-bold">Access:</span>
              Request access to your personal data.
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Correction:</span> Request correction
              of any inaccurate or incomplete data.
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Deletion:</span>
              Request deletion of your data when no longer necessary.
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Restriction of Processing:</span>
              Request restriction of data processing under specific conditions.
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Data Portability:</span>
              Request to transfer your data to another organization.
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Objection:</span>
              Object to processing based on legitimate interests.
            </li>
          </ul>
          <p className="text-gray-600">
            To exercise these rights, please contact us at the contact
            information provided below.
          </p>
        </section>
        <section>
          <h2 className="text-xl text-gray-900 font-semibold mb-4">
            8. Amendments to This Policy
          </h2>
          <p className="text-gray-600 mb-6">
            Cogntix reserves the right to update this privacy policy in response
            to changing legal, regulatory, or operational requirements. Any
            changes will be posted on this page, and users are encouraged to
            review it periodically.
          </p>
          <p className="text-gray-600 mb-6">
            For questions or to exercise your data rights, please contact us at:
          </p>
          <ul className="list-none">
            <li className="text-gray-600">
              <span className="font-bold">Email: </span>
              <a
                href="mailto:hai@cogntix.com"
                className="text-primary font-medium"
              >
                hai@cogntix.com
              </a>
            </li>
            <li className="text-gray-600">
              <span className="font-bold">Address: </span>No 218, Stanley Road,
              Jaffna, Sri Lanka.
            </li>
          </ul>
        </section>
      </div>
      <FooterSection />
    </>
  );
};

export default PrivacyPolicy;
