import React from "react";
import Navbar from "../components/Navbar";
import Features from "../components/Subcriptions/Features";
import DevelopmentPricing from "../components/Subcriptions/DevelopmentPricing";
import DesignPricing from "../components/Subcriptions/DesignPricing";
import Testimonials from "../components/Subcriptions/Testimonials";
import DemoCallSection from "../components/LandingPage/DemoCallSection";
import FooterSection from "../components/FooterSection";
import { Helmet } from "react-helmet";

const Subscriptions = () => {
  const DotPattern = () => (
    <svg
      className="absolute inset-0 w-full h-full"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <pattern
          id="dotPattern"
          x="0"
          y="0"
          width="20"
          height="20"
          patternUnits="userSpaceOnUse"
        >
          <circle cx="1.5" cy="1.5" r="1.5" fill="rgba(255, 255, 255, 0.1)" />
        </pattern>
      </defs>
      <rect width="100%" height="575px" fill="url(#dotPattern)" />
    </svg>
  );

  return (
    <>
      <Helmet>
        <title>Dev & Design Subscriptions</title>
        <meta
          name="description"
          content="Start your journey with Cogntix today. Our subscription plans are designed to scale with your business, offering affordable and tailored software solutions."
        />
        <meta
          name="keywords"
          content="Cogntix subscription plans, Software subscription service, Cogntix pricing plans, Cogntix software support, Affordable subscription plans, Software pricing plans"
        />
      </Helmet>
      <Navbar />
      <div className="w-full bg-primary ">
        <DotPattern />
        <div className="flex flex-col mt-[100px] lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] py-16 px-2">
          <h2 className="semiHeading text-left">SUBSCRIPTIONS</h2>
          <h1 className="lg:text-6xl text-[32px] text-white font-bold mb-6 w-[95%] md:max-w-[900px]">
            Developers & Designers For Ambitious Startups
          </h1>
          <p className="max-w-[750px] whiteParagraph mb-10">
            Cogntix is like your in-house team but with great amount of
            experience, no overheads costs, and zero operational stress
          </p>
        </div>
      </div>
      <Features />
      <DevelopmentPricing />
      <DesignPricing />
      <Testimonials />
      <DemoCallSection />
      <FooterSection />
    </>
  );
};

export default Subscriptions;
