import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import { PiCaretLeftBold } from "react-icons/pi";
import projectDetail7 from "../../assests/Project7/ProjectDetail7.png";
import project7Sample1 from "../../assests/Project7/project7sample1.png";
import project7Sample2 from "../../assests/Project7/project7sample2.png";
import project7Sample3 from "../../assests/Project7/project7sample3.png";
import { FaCheck } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import DemoCallSection from "../LandingPage/DemoCallSection";
import FooterSection from "../FooterSection";
import { Helmet } from "react-helmet";

const ImagePreview = ({ isOpen, image, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div className="relative max-w-[90vw] max-h-[90vh]">
        <button
          onClick={onClose}
          className="absolute -top-8 -right-5 text-white hover:text-gray-300"
        >
          <div className="flex justify-center items-center bg-gray-200 rounded-full p-1">
            <IoMdClose className="w-5 h-5 text-gray-600" />
          </div>
        </button>
        <img
          src={image}
          alt="Preview"
          className="max-w-full max-h-[85vh] object-contain"
          onClick={(e) => e.stopPropagation()}
        />
      </div>
    </div>
  );
};

const Project7 = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
  };

  const tags = ["Market research", "Ideation", "UI/UX Design"];

  const projectInfo = [
    { label: "Industry", value: "Finance" },
    { label: "Location", value: "Switzerland" },
    { label: "Client", value: "Gobiraj Karunananthan" },
    { label: "Time", value: "2 weeks" },
  ];

  const objectivesList = [
    "Provide an easy way for users to view both pending and settled payments, ensuring they stay on top of their financial obligations.",
    "Enable users to attach payment proofs for each transaction, ensuring accuracy and reducing errors.",
    "Keep all customer billing records, payment histories, and outstanding balances organized and accessible in one place.",
    "Offer detailed customer profiles to help users manage billing details and track payment histories efficiently.",
    "Reduce the time spent on manual tracking and record-keeping, making the bill management process faster and more automated.",
  ];

  const projectSamples = [
    { image: project7Sample1, alt: "project7Sample1" },
    { image: project7Sample2, alt: "project7Sample2" },
    { image: project7Sample3, alt: "project7Sample3" },
  ];

  return (
    <>
      <Helmet>
        <title>Customers Bills Management App for Swiss Small Businesses</title>
        <meta
          name="description"
          content="This case study is about how Cogntix helped a Swiss-based company develop a user-friendly bill management app, simplifying bill tracking, payment verification, and financial organization for both individuals and businesses. "
        />
        <meta
          name="keywords"
          content="Cogntix Case Study, Bill Management App, Financial, Bill tracking app, mobile app for bill payments"
        />
      </Helmet>
      <Navbar />
      <div className="bg-white lg:mt-[150px] mt-[100px] lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <div
          onClick={() => handleNavigate("/ourWorks")}
          className="flex felx-row gap-3 items-center group cursor-pointer"
        >
          <PiCaretLeftBold className="text-xl text-gray-500 group-hover:text-secondary" />
          <p className="text-lg text-gray-500 group-hover:text-secondary">
            Back to Our works
          </p>
        </div>
        <div className="justify-start mt-4">
          <div className="mt-10 flex flex-wrap gap-3 md:w-[40%] w-full">
            {tags.map((tag, index) => (
              <div
                key={index}
                className="bg-gray-200 inline-flex justify-center items-center rounded-3xl py-1 px-4 text-primary font-medium text-sm whitespace-nowrap"
              >
                {tag}
              </div>
            ))}
          </div>
          <h1 className="ourWorkHeading mb-6 mt-4 lg:w-[80%]">
            Customers Bills Management App for Swiss Small Businesses
          </h1>
          <p className="lg:w-[65%] text-text2 text-lg">
            Cogntix worked with a Swiss-based company to develop a mobile app
            that makes bill management and payment tracking easier for both
            individuals and businesses.
          </p>
          <div className="mt-12 lg:w-[80%] grid md:grid-cols-4 grid-cols-2 lg:gap-12 gap-8">
            {projectInfo.map((info, index) => (
              <div key={index}>
                <p className="text-sm text-gray-700 uppercase">{info.label}</p>
                <p className="text-xl text-text4 font-semibold font-inter">
                  {info.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full lg:mt-14 mt-10 lg:px-[90px] larger:px-[200px] mx-auto max-w-[2000px] px-2">
        <img
          src={projectDetail7}
          alt="projectDetail7"
          className="w-full h-full"
        />
      </div>

      <div className="lg:px-[100px] larger:px-[200px] mx-auto max-w-[2000px] px-2 my-20">
        <div className="flex flex-col gap-20">
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Background</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  The app simplifies financial tracking by offering users a
                  clear and centralized view of their outstanding balances,
                  payments, and other details. Many users struggle to keep track
                  of pending bills, verify payment proofs, and maintain
                  organized financial records. This often leads to confusion and
                  mismanagement, affecting both personal and business financial
                  health. To address these pain points, the Bill Management App
                  was developed as an intuitive and user-friendly solution to
                  help users easily manage bills, track payments, and access all
                  necessary information in one place.
                </span>
                <br />
                <span>
                  With no technical expertise required, users can stay organized
                  and up-to-date with their financial obligations and records.
                </span>
              </p>
            </div>
          </div>

          {/* Objective Section */}
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Objective</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  The app’s main objective was to create a platform that
                  simplifies bill management, ensures easy verification and
                  tracking of payments, and gives users a clear overview of
                  their financial standing, all in one place. It was built to be
                  accessible to users with no technical skills, ensuring that
                  anyone can efficiently manage their bills and payments.
                </span>
                <br />
              </p>
              <div className="flex flex-col gap-6 mt-10">
                {objectivesList.map((objective, index) => (
                  <div key={index} className="flex flex-row  gap-3">
                    <div>
                      <FaCheck className="size-6 text-secondary mt-1" />
                    </div>
                    <div>
                      <p className="text-lg text-text2">{objective}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Solution Section - Not using map as requested */}
          <div className="flex md:flex-row flex-col gap-6">
            <div className="lg:w-[40%] md:w-[50%] sm:w-full">
              <h1 className="ourWorkHeading">Solution</h1>
            </div>
            <div className="lg:w-[60%] md:w-[50%] sm:w-full text-lg text-text2">
              <p className="flex flex-col text-lg text-text2">
                <span>
                  To solve the challenges, we created an easy-to-use mobile app
                  that lets users add bills, track payments, and view customer
                  profiles without any technical skills. The app includes a
                  simple system to manage bills and payments, giving users a
                  clear view of their finances. We also added a feature to
                  upload payment proofs for easy verification, making everything
                  more transparent and reducing manual work.
                </span>
                <br />
                <span>
                  With the solution provided by Cogntix, users can now easily
                  track pending and settled bills, ensuring they never miss a
                  payment. The app has helped users to manage finances easily,
                  reduce administrative tasks and save time while improving
                  accuracy.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex lg:flex-row flex-col mt-20 w-full gap-5 lg:max-h-[500px]">
        {projectSamples.map((image, index) => (
          <img
            key={index}
            src={image.image}
            alt={image.alt}
            className="w-full cursor-pointer transition-transform hover:scale-[1.02]"
            onClick={() => setSelectedImage(image.image)}
          />
        ))}
      </div>

      <ImagePreview
        isOpen={!!selectedImage}
        image={selectedImage}
        onClose={() => setSelectedImage(null)}
      />

      <DemoCallSection />
      <FooterSection />
    </>
  );
};

export default Project7;
