import React from "react";
import { IoLogoLinkedin } from "react-icons/io5";
import Naresh from "../../assests/Naresh_.png";
import Sooriyakumar from "../../assests/Sooriyakumar.png";
import Vijay from "../../assests/Vijay.png";
import Sheron from "../../assests/Sheron.png";
import Thibhishna from "../../assests/Thibhishna.png";
import Iynkaran from "../../assests/Iynkaran.png";

const teamData = [
  {
    id: 1,
    name: "Naresh Shanmugaraj",
    position: "Founder & CEO",
    image: Naresh,
    linkedIn: "https://www.linkedin.com/in/naresh-shanmugaraj/",
  },
  {
    id: 2,
    name: "Sooriyakumar Sittampalam",
    position: "Senior Software Engineer",
    image: Sooriyakumar,
    linkedIn: "https://www.linkedin.com/in/sooriyakumar-sittampalam/",
  },
  {
    id: 3,
    name: "Vijay Kandasamy",
    position: "UI/UX Designer",
    image: Vijay,
    linkedIn: "https://www.linkedin.com/in/vijay-kandasamy/",
  },
  {
    id: 4,
    name: "Sheron Jude Selvakumar",
    position: "Software Engineer",
    image: Sheron,
    linkedIn: "https://www.linkedin.com/in/sheronjude98/",
  },
  {
    id: 5,
    name: "Thibishna Sriskantharasa",
    position: "Software Engineer - Test",
    image: Thibhishna,
    linkedIn: "https://www.linkedin.com/in/thibishna-sriskantharasa-a69b181ba/",
  },
  {
    id: 6,
    name: "Iynkaran Pavanantham",
    position: "Intern Software Engineer",
    image: Iynkaran,
    linkedIn: "https://www.linkedin.com/in/iynkaran-pavanantham-b54991263/",
  },
];

const TeamMemberCard = ({ name, position, image, linkedIn }) => (
  <div className="w-full">
    <img src={image} alt={name} className="w-full" />
    <div className="px-4 flex flex-row justify-between items-center">
      <div>
        <p className="text-white text-xl font-semibold">{name}</p>
        <p className="text-gray-400 font-medium">{position}</p>
      </div>
      <div>
        <a
          href={linkedIn}
          target="_blank"
          rel="noopener noreferrer"
          className="hover:opacity-75 transition-opacity"
        >
          <IoLogoLinkedin className="size-6 text-gray-400 hover:text-white" />
        </a>
      </div>
    </div>
  </div>
);

const Team = () => {
  const firstRow = teamData.slice(0, 3);
  const secondRow = teamData.slice(3);

  return (
    <div className="bg-primary">
      <div className="flex flex-col lg:p-[100px] larger:px-[200px] mx-auto max-w-[2000px] py-16 px-3 bg-primary">
        <div className="flex flex-col justify-center">
          <h2 className="semiHeading text-center">OUR TEAM</h2>
          <h2 className="md:text-6xl text-[30px] text-white font-bold mb-6 text-center">
            Meet Our Team
          </h2>
          <p className="text-center text-white text-lg mb-16 lg:mx-auto mx-3 lg:max-w-[880px]">
            Meet the people steering Cogntix to success.
          </p>
        </div>

        <div className="flex md:flex-row flex-col gap-6 w-full md:mb-20">
          {firstRow.map((member) => (
            <TeamMemberCard key={member.id} {...member} />
          ))}
        </div>

        <div className="flex md:flex-row flex-col gap-6 w-full md:mt-0 mt-6">
          {secondRow.map((member) => (
            <TeamMemberCard key={member.id} {...member} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
