import React, { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import groupImage2 from "../../assests/GroupImage2.png";

const CountUp = ({ end, duration = 1000 }) => {
  const [count, setCount] = useState(0);
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    if (!inView) return;

    let startTimestamp;
    const step = (timestamp) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);

      setCount(Math.floor(progress * end));

      if (progress < 1) {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  }, [end, duration, inView]);

  return (
    <div ref={ref} className="text-[32px] text-white font-bold">
      {count}
      {end >= 98 ? "%" : "+"}
    </div>
  );
};

const StatsSection = () => {
  const stats = [
    {
      value: 8,
      description: "Successfully completed diverse projects across industries.",
    },
    {
      value: 10,
      description:
        "A growing team of skilled professionals driving innovation.",
    },
    {
      value: 99,
      description: "Consistently exceeding client expectations with quality.",
    },
    {
      value: 8,
      description: "Partnered with clients to achieve their business goals.",
    },
  ];

  return (
    <>
      <div className="bg-primary">
        <div className="w-full bg-primary flex lg:flex-row flex-col larger:pl-[100px] larger:pr-[200px] mx-auto max-w-[2000px]">
          <div className="lg:w-[50%] ">
            <div className="flex flex-col lg:mt-[100px] mt-10 lg:pl-[100px] px-2">
              <h2 className="semiHeading text-left">KEY MILESTONES</h2>
              <h2 className="lg:text-6xl lg:w-[90%] text-[32px] text-white font-bold mb-6">
                Our Performance Over Time!
              </h2>
              <p className="lg:w-[85%] whiteParagraph mb-10">
                Our key milestones reflect our dedication to excellence and
                innovation, showcasing our growth journey and commitment to
                delivering impactful solutions for our clients.
              </p>
              <div className="flex lg:flex-row flex-col gap-6 mb-8">
                {stats.slice(0, 2).map((stat, index) => (
                  <div
                    key={index}
                    className="flex flex-col gap-2 text-left"
                  >
                    <CountUp end={stat.value} />
                    <p className="whiteParagraph lg:w-[90%]">
                      {stat.description}
                    </p>
                  </div>
                ))}
              </div>
              <div className="flex lg:flex-row flex-col gap-6 mb-4">
                {stats.slice(2, 4).map((stat, index) => (
                  <div
                    key={index}
                    className="flex flex-col gap-2 text-left"
                  >
                    <CountUp end={stat.value} />
                    <p className="whiteParagraph lg:w-[90%]">
                      {stat.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="lg:w-[50%] flex justify-end">
            <img src={groupImage2} alt="groupImage2" className="w-full" />
          </div>
        </div>
      </div>
    </>
  );
};

export default StatsSection;
